import { useFormikContext } from 'formik'
import React, { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Registration, Routing, validators } from 'traficom-registry-shared'
import { api } from '../../services/api'
import { RegistrationContext } from '../../state/registration'
import { addSnackbarMessage } from '../../state/registration/actions'
import {
  CancelButton,
  CheckboxField,
  FieldGroup,
  Form,
  Section,
  Submit,
  SubmitButton,
  useFormErrorHandler,
} from '../../ui-common/form'
import { Comp } from '../../utils/component'
import { AddressGroup, ContactFields, IdentificationFields, PersonFields } from './fields'
import { Register, registerDefaults, RegisterInitial, resetRegister } from './pilot-data'

export const PilotOperatorInfo: Comp<RegisterInitial> = props => {
  const { t } = useTranslation()
  const { dispatch } = useContext(RegistrationContext)

  const handleError = useFormErrorHandler<Register>()

  const submit: Submit<Register> = async (values, helpers) => {
    const { setTouched, setValues, setSubmitting } = helpers
    const result = await (values.operatorKind === 'organization'
      ? api.registerPilotOrganization(values)
      : api.registerPilotPerson(values))
    if (result.success) {
      dispatch(addSnackbarMessage(t('registration:operator_pilot_added'), 'SUCCESS'))
      setTouched({})
      setValues(resetRegister(values))
      setSubmitting(false)
    } else {
      handleError(result.data, helpers)
    }
  }

  return (
    <Form<Register> initialValues={registerDefaults(props)} onSubmit={submit} validate={validators.operatorPilot}>
      <CredentialSection operatorKind={props.operatorKind} />
      <PilotSection />
    </Form>
  )
}

const CredentialSection: Comp<{ operatorKind: Registration.OperatorKind }> = ({ operatorKind }) => {
  const { t } = useTranslation('registration')

  return (
    <Section>
      <p>{t('operator_pilot_form_note')}</p>
      {operatorKind === 'person' && (
        <PersonFields disabled mode="with-ssn" namespace="credentials" titleKey="registration:operator_info" />
      )}
      <IdentificationFields enableChangeIdentifier={operatorKind === 'organization'} role="operator" />
    </Section>
  )
}

const PilotSection: Comp = () => {
  const { t } = useTranslation()
  const { values } = useFormikContext<Register>()

  return (
    <Section title={t('registration:pilot_test_participant_info')}>
      <PersonFields mode="with-ssn" namespace="pilot" />
      <ContactFields namespace="pilot" />
      <AddressGroup addressNamespace="pilot.address" kind="foreign" />
      <FieldGroup>
        <CheckboxField label={t('personal_data:parental_confirmation')} name="parentalConfirmation" />
      </FieldGroup>
      <p>
        <Trans i18nKey="personal_data:pilot_test_email_confirm_info">
          Pilottikokeeseen kutsu toimitetaan sähköpostiosoitteeseen <strong>{{ email: values.pilot.email }}</strong>.
          Tarkista sähköpostin oikeellisuus.
        </Trans>
      </p>
      <SubmitButton labelKey="registration:pilot_test_register" />
      <CancelButton to={Routing.patterns.droneRoot} />
    </Section>
  )
}
