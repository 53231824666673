import React, { useContext, useEffect, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { RegistrationContext } from '../../state/registration'
import { removeSnackbarMessage } from '../../state/registration/actions'
import Button from '../button/button'
import { theme } from '../../styling/theme'
import { useTranslation } from 'react-i18next'

const SnackbarArea = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;

  @media (min-width: 768px) {
    right: ${p => p.theme.spacing(3)};
    bottom: ${p => p.theme.spacing(3)};
    width: auto;
  }
`

const alertLevelColors: Record<AlertLevel, string> = {
  SUCCESS: theme.palette.primary[500],
  ERROR: theme.palette.error,
  INFO: theme.palette.secondary,
}

const SnackBarContainer = styled.div<{ level: AlertLevel }>`
  display: flex;
  flex-grow: initial;
  min-width: 288px;
  align-items: center;
  padding: ${p => p.theme.spacing(0.5)} ${p => p.theme.spacing(2)};
  margin: ${p => p.theme.spacing(0.5)} 0;
  background-color: ${p => alertLevelColors[p.level]};
  color: white;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
`
const SnackbarMessageElem = styled.div`
  padding: 8px 0;
`
const SnackbarAction = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: -${p => p.theme.spacing(0.5)};
  padding-left: ${p => p.theme.spacing(1)};
`
const slideUp = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
`

const slideRight = keyframes`
  0% {
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
`

const SnackbarAnimator = styled.div<{ state?: 'enter' | 'leave' }>`
  ${p =>
    p.state === 'enter' &&
    css`
      animation: 1s ${slideUp};
    `}
  ${p =>
    p.state === 'leave' &&
    css`
      animation: 0.5s ${slideRight};
    `}
`

const CloseButton = styled(Button)`
  background-color: inherit;
  &:before {
    content: '\\2715';
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`
export type AlertLevel = 'SUCCESS' | 'ERROR' | 'INFO'

export type SnackbarMessage = {
  id: number
  text: string
  level: AlertLevel
}

export type SnackbarItemProps = SnackbarMessage & {
  onClose: (id: number) => void
}

export const SnackbarItem: React.FunctionComponent<SnackbarItemProps> = ({ id, text, level, onClose }) => {
  const close = () => {
    setState('leave')
    setTimeout(() => {
      setState(undefined)
      onClose(id)
    }, 500)
  }
  const [state, setState] = useState<'enter' | 'leave' | undefined>(undefined)

  useEffect(() => {
    setState('enter')
    setTimeout(() => setState(undefined), 1000)
  }, [])

  const { t } = useTranslation()

  return (
    <SnackbarAnimator key={id} state={state}>
      <SnackBarContainer role="alert" level={level}>
        <SnackbarMessageElem>{text}</SnackbarMessageElem>
        <SnackbarAction>
          <CloseButton onClick={close} aria-label={t('close')} />
        </SnackbarAction>
      </SnackBarContainer>
    </SnackbarAnimator>
  )
}

const Snackbar: React.FunctionComponent = () => {
  const { state, dispatch } = useContext(RegistrationContext)
  const { snackbarMessage } = state
  const onClose = (id: number) => {
    dispatch(removeSnackbarMessage(id))
  }

  return (
    <SnackbarArea>
      {snackbarMessage && (
        <SnackbarItem id={snackbarMessage.id} text={snackbarMessage.text} level={snackbarMessage.level} onClose={onClose} />
      )}
    </SnackbarArea>
  )
}

export default Snackbar
