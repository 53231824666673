import { CodeTextDto } from '../traficom-api'

export type Value = Required<CodeTextDto>

export type Name = typeof names[number]

export const names = [
  'Maat',
  'Postitoimipaikat',
  'Sukupuoli',
  'KdDroneErrorMessages',
  'KdDroneServiceLanguages',
  'KdDroneRegistrationPeriod',
  'KdDroneRegistrationType',
] as const
