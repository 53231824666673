import React from 'react'
import { useTranslation } from 'react-i18next'
import EmptyState from '../../ui-common/empty-state/empty-state'
import { Comp } from '../../utils/component'
import PageMeta from '../metadata/page-meta'

export const UpdateFinish: Comp = () => {
  const { t } = useTranslation()

  const title = t('common:finished')

  return (
    <>
      <PageMeta subTitle={title} />
      <EmptyState state="success" title={title}>
        <p>{t('registration:info_update_successful')}</p>
      </EmptyState>
    </>
  )
}
