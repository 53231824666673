import React from 'react'
import { useTranslation } from 'react-i18next'
import { Registrant } from 'traficom-registry-shared'
import { RadioGroupBooleanField } from '../../../ui-common/form'
import { Comp } from '../../../utils/component'

type Props = { namespace: string | undefined; forOrganization?: boolean }

export const ConsentFields: Comp<Props> = ({ namespace, forOrganization }) => {
  const { t } = useTranslation('personal_data')

  const fields = forOrganization ? Registrant.organizationConsentOptions : Registrant.consentOptions

  return (
    <>
      {fields.map(name => (
        <RadioGroupBooleanField
          key={name}
          label={t(labelKeys[name])}
          name={getName(namespace, name)}
          options={[
            { value: 'true', label: t('common:yes') },
            { value: 'false', label: t('common:no') },
          ]}
        />
      ))}
    </>
  )
}

const labelKeys: Record<Registrant.ConsentOption, string> = {
  developmentAndInnovationActivities: 'nda.developmentAndInnovationActivities',
  directMarketing: 'nda.directMarketing',
  individualRelease: 'nda.individualRelease',
  openInterfaces: 'nda.openInterfaces',
  transportRelatedPurposes: 'nda.transportRelatedPurposes',
}

const getName = (namespace: string | undefined, name: Registrant.ConsentOption): string =>
  namespace ? `${namespace}.${name}` : name
