import { Identification, Registrant, Registration } from 'traficom-registry-shared'
import { FormValues } from '../../utils/types'

export type RegisterPerson = Registrant.OperatorPerson & { hasForeignAddress: boolean }
export type RegisterOrganization = OrganizationFields & { contact: Registrant.ContactPerson; hasForeignAddress: boolean }

// All fields from both kinds of organizations since it's possible to switch dynamically
type OrganizationFields = Omit<Registrant.OrganizationWithFinnishId, 'hasFinnishId'> &
  Omit<Registrant.OrganizationWithoutFinnishId, 'hasFinnishId'> & { hasFinnishId: boolean }

export type UpdateAuthMode = 'NONE' | 'STRONG'
export type UpdateProps = { mode: 'NONE' } | { mode: 'STRONG'; identification: Identification.StrongData }
export type UpdateOrganization = Registrant.UpdateOperatorOrganization & { hasForeignAddress: boolean }
export type UpdatePerson = Registrant.UpdateOperatorPerson & { hasForeignAddress: boolean }

export type ExtendProps = UpdateProps & { operatorKind: Registration.OperatorKind }
export type Extend = Registrant.ExtendOperatorBase

export const address = {
  street: '',
  municipality: '',
  postalCode: '',
  country: '',
}

export const consents = {
  developmentAndInnovationActivities: '',
  directMarketing: '',
  individualRelease: '',
  openInterfaces: '',
  transportRelatedPurposes: '',
} as const

const contactInfo = { email: '', phone: '' }
const personBase = { ...contactInfo, firstNames: '', lastName: '' }
const personDetails = { birthCountry: '', birthDate: '', gender: '', nationality: '' }

export const personWithConsents: FormValues<Registrant.PersonWithConsents> = { ...personBase, ...personDetails, consents }

const operatorBase = {
  insuranceInfo: '',
  confirmationOfCompetence: false,
  registrationPeriod: '',
  address,
  hasForeignAddress: false,
} as const

const updateBase = {
  ...contactInfo,
  address,
  hasForeignAddress: false,
  language: '',
  insuranceInfo: '',
} as const

export const organizationUpdateDefaults = (authType: UpdateAuthMode): FormValues<UpdateOrganization> =>
  authType === 'STRONG' ? organizationUpdateStrong() : organizationUpdateNone()

const organizationUpdateStrong = (): FormValues<UpdateOrganization> & { authType: 'STRONG' } => ({
  operatorKind: 'organization',
  authType: 'STRONG',
  operatorIdentifier: '',
  changeIdentifier: '',

  contactAction: 'update',
  contact: contactInfo,

  ...updateBase,
})

const organizationUpdateNone = (): FormValues<UpdateOrganization> & { authType: 'NONE' } => ({
  operatorKind: 'organization',
  authType: 'NONE',
  operatorIdentifier: '',
  changeIdentifier: '',

  contactAction: 'update',
  contact: personWithConsents,

  ...updateBase,
})

export const personUpdateDefaults = (authType: UpdateAuthMode): FormValues<UpdatePerson> =>
  authType === 'STRONG' ? personUpdateStrong() : personUpdateNone()

const personUpdateStrong = (): FormValues<UpdatePerson> & { authType: 'STRONG' } => ({
  operatorKind: 'person',
  authType: 'STRONG',
  operatorIdentifier: '',

  ...updateBase,
})

const personUpdateNone = (): FormValues<UpdatePerson> & { authType: 'NONE' } => ({
  operatorKind: 'person',
  authType: 'NONE',
  operatorIdentifier: '',
  changeIdentifier: '',
  consents,

  ...updateBase,
})

const personEmail = (identification: Identification.EmailData): FormValues<Registrant.PersonEmailAuth> => ({
  authType: 'EMAIL',
  ...personWithConsents,
  ...identification,
})

const personStrong = (identification: Identification.StrongData): Registrant.PersonStrongAuth => ({
  authType: 'STRONG',
  ...personBase,
  ...identification,
})

export const personDefaults = (i: Identification.IdentifiedState): FormValues<RegisterPerson> =>
  i.mode === 'EMAIL'
    ? { ...personEmail(i.data), ...operatorBase, language: '' }
    : { ...personStrong(i.data), ...operatorBase, registerAsPilot: '' }

export const organizationDefaults = (i: Identification.IdentifiedState): FormValues<RegisterOrganization> => ({
  ...operatorBase,
  ...contactInfo,
  name: '',
  hasFinnishId: '',
  finnishOrganizationId: '',
  foreignOrganizationId: '',
  signingAuthority: false,
  language: '',
  developmentAndInnovationActivities: '',
  contact: i.mode === 'EMAIL' ? personEmail(i.data) : personStrong(i.data),
})

const extendBase = { operatorIdentifier: '', email: '', registrationPeriod: '' } as const

export const extendDefaults = ({ mode, operatorKind }: ExtendProps): FormValues<Extend> =>
  mode === 'NONE' || operatorKind === 'organization'
    ? { ...extendBase, authType: 'NONE', operatorKind, changeIdentifier: '' }
    : { ...extendBase, authType: 'STRONG', operatorKind }
