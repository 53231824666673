import { Codeset, Identification, Payment, Registrant } from '../model'
import {
  ConfigResult,
  EmailVerificationPayload,
  FrontendError,
  RegistrationResponse,
  StartVerification,
  VerifyEmailResponse,
} from './Model'

export type DroneEndpoints = typeof routes & {
  codeset: { path: { codesetName: Codeset.Name; lang: string }; body: undefined; result: Codeset.Value[] }
  extendOperator: { path: undefined; body: Registrant.ExtendOperator; result: RegistrationResponse }
  orderStatus: { path: { orderNumber: string }; body: undefined; result: Payment.Details }
  /** Return from payment operator, redirects to frontend. */
  paymentCancel: { path: undefined; body: undefined; result: undefined }
  /** Return from payment operator, redirects to frontend. */
  paymentConfirm: { path: undefined; body: undefined; result: undefined }
  /** Notification from payment operator, service-to-service */
  paymentNotification: { path: undefined; body: undefined; result: undefined }
  config: { path: undefined; body: undefined; result: ConfigResult }
  registerOperator: { path: undefined; body: Registrant.Operator; result: RegistrationResponse }
  registerPilot: { path: undefined; body: Registrant.IndependentPilot; result: RegistrationResponse }
  registerPilotOrganization: { path: undefined; body: Registrant.OperatorPilotOrganization; result: undefined }
  registerPilotPerson: { path: undefined; body: Registrant.OperatorPilotPerson; result: undefined }
  reportError: { path: undefined; body: FrontendError; result: undefined }
  sessionInfo: { path: undefined; body: undefined; result: Identification.State }
  startVerification: { path: undefined; body: StartVerification; result: { message: string } }
  updateOperator: { path: undefined; body: Registrant.UpdateOperator; result: undefined }
  updatePilot: { path: undefined; body: Registrant.UpdatePilot; result: undefined }
  verifyEmail: { path: undefined; body: EmailVerificationPayload; result: VerifyEmailResponse }
}

export const routes = {
  codeset: { method: 'get', pattern: '/codeset/:codesetName/:lang', security: 'none' },
  /** Operator registration period extension, authentication requirement depends on the model. */
  extendOperator: { method: 'put', pattern: '/extend-operator', security: 'none' },
  orderStatus: { method: 'get', pattern: '/order/:orderNumber', security: 'none' },
  paymentCancel: { method: 'get', pattern: '/payment/cancel', security: 'none' },
  paymentConfirm: { method: 'get', pattern: '/payment/success', security: 'none' },
  paymentNotification: { method: 'get', pattern: '/payment/notification', security: 'none' },
  config: { method: 'get', pattern: '/config', security: 'none' },
  registerOperator: { method: 'put', pattern: '/register-operator', security: 'authentication' },
  /** Independent pilot registration requires authentication */
  registerPilot: { method: 'put', pattern: '/register-pilot', security: 'authentication' },
  /** Organization operator pilot registration relies on operator change identifier */
  registerPilotOrganization: { method: 'put', pattern: '/register-pilot-organization', security: 'none' },
  /** Person operator pilot registration requires strong authentication */
  registerPilotPerson: { method: 'put', pattern: '/register-pilot-person', security: 'authentication' },
  reportError: { method: 'post', pattern: '/errors', security: 'none' },
  sessionInfo: { method: 'get', pattern: '/session-info', security: 'none' },
  startVerification: { method: 'post', pattern: '/start-verification', security: 'captcha' },
  /** Operator update, authentication requirement depends on the upgrade model. */
  updateOperator: { method: 'put', pattern: '/update-operator', security: 'none' },
  /** Operator update, authentication requirement depends on the upgrade model. */
  updatePilot: { method: 'put', pattern: '/update-pilot', security: 'none' },
  verifyEmail: { method: 'post', pattern: '/verify-email', security: 'captcha' },
} as const

export type EndpointName = keyof typeof routes

export const endpointNames = Object.keys(routes) as EndpointName[]

export type DroneRoutes = typeof routes
