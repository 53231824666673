import React from 'react'
import { useTranslation } from 'react-i18next'
import { Country, Registrant } from 'traficom-registry-shared'
import { FieldGroup, SelectField, TextField } from '../../../ui-common/form'
import { BirthDateField } from '../../../ui-common/form/field'
import { GenderField } from './single-fields'

type Mode = 'with-ssn' | 'no-ssn' | 'name-only'

type Props = {
  disabled?: boolean // Properly supported only in 'with-ssn' mode
  mode: Mode
  namespace?: string
  optional?: boolean
  titleKey?: string
}

export const PersonFields: React.FC<Props> = ({ disabled, mode, namespace, optional, titleKey }) => {
  const { t } = useTranslation('personal_data')

  return (
    <>
      <FieldGroup title={t(titleKey ?? 'name')}>
        <TextField
          label={t('first_names')}
          name={getName(namespace, 'firstNames')}
          disabled={disabled}
          optional={optional}
        />
        <TextField label={t('last_name')} name={getName(namespace, 'lastName')} disabled={disabled} optional={optional} />
        {mode === 'with-ssn' && (
          <TextField
            label={t('ssn')}
            upperCase
            noWhitespace
            name={getName(namespace, 'ssn')}
            disabled={disabled}
            optional={optional}
          />
        )}
      </FieldGroup>
      {mode === 'no-ssn' && (
        <>
          <FieldGroup title={t('birth_and_nationality')}>
            <BirthDateField label={t('birth_date')} name={getName(namespace, 'birthDate')} />
            <SelectField
              label={t('birth_country')}
              name={getName(namespace, 'birthCountry')}
              optional={optional}
              options={Country.options}
            />
            <SelectField
              label={t('nationality')}
              name={getName(namespace, 'nationality')}
              optional={optional}
              options={Country.options}
            />
          </FieldGroup>
          <GenderField name={getName(namespace, 'gender')} />
        </>
      )}
    </>
  )
}

type FieldName = keyof Registrant.PersonEmailAuth | keyof Registrant.PersonStrongAuth

const getName = (namespace: string | undefined, name: FieldName): string => (namespace ? `${namespace}.${name}` : name)
