import React from 'react'
import { useTranslation } from 'react-i18next'
import { Identification, Locale, validators } from 'traficom-registry-shared'
import { api } from '../../services/api'
import { Paragraphs } from '../../ui-common'
import { Form, Section, Submit, useFormErrorHandler } from '../../ui-common/form'
import { Comp } from '../../utils/component'
import { AddressGroup, ConsentFields, ContactFields, Hello, LanguageField, PersonFields } from './fields'
import { PilotPayment } from './period-payment-fields'
import { Independent, independentDefaults } from './pilot-data'

type Props = { identification: Identification.IdentifiedState }

export const PilotIndependentInfo: React.FC<Props> = ({ identification }) => {
  const { i18n } = useTranslation()

  const handleError = useFormErrorHandler<Independent>()

  const submit: Submit<Independent> = async (values, helpers) => {
    const result = await api.registerPilot({ ...values, userLanguage: i18n.language as Locale.UserLanguage })
    if (result.success) {
      window.location.href = result.data.url
    } else {
      handleError(result.data, helpers)
    }
  }

  return (
    <Form<Independent>
      key={identification.mode} // Needed to reset form values properly if the user changes identification method
      initialValues={independentDefaults(identification)}
      onSubmit={submit}
      validate={validators.independentPilot}
    >
      <PersonalData identification={identification} />
      {identification.mode === 'EMAIL' && (
        <Section>
          <ConsentFields namespace="consents" />
        </Section>
      )}
      <PilotPayment showEmailConfirm={identification.mode === 'STRONG'} />
    </Form>
  )
}

const PersonalData: Comp<Props> = ({ identification }) => {
  const { t } = useTranslation('personal_data')

  return (
    <Section title={t('personal_data')}>
      {identification.mode === 'STRONG' && <Hello person={identification.data} />}
      <Paragraphs>{t('pilot_independent_form_description')}</Paragraphs>
      {identification.mode === 'EMAIL' && <PersonFields mode="no-ssn" />}
      <AddressGroup kind={identification.mode === 'STRONG' ? 'non-easa' : 'domestic-or-non-easa'} />
      <ContactFields disableEmail={identification.mode === 'EMAIL'} />
      {identification.mode === 'EMAIL' && <LanguageField />}
    </Section>
  )
}
