import { DroneEndpoints, DroneRoutes, EndpointName, endpointNames, routes } from './Drone'
import * as Model from './Model'

export { DroneEndpoints as Endpoints, EndpointName as Name, Model, endpointNames, routes }

export type SuccessCode = 200 | 302
export type ErrorCode = 400 | 401 | 403 | 404 | 500

export type Endpoint<Name extends EndpointName = EndpointName> = DroneEndpoints[Name]
export type Route<Name extends EndpointName = EndpointName> = DroneRoutes[Name]
