import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Col, Container, Row } from '../../ui-common/layout/layout'
import SectionWithBackground from '../../ui-common/section/SectionWithBackground'
import { H1 } from '../../ui-common/typography/heading'
import Card from '../../ui-common/card/card'
import backgroundImage from '../../images/BG_web.jpg'
import frontpageImageNg from '../../images/drone_delivery_web.jpg'
import frontpageImageSkyLabX from '../../images/air_navigation_web.jpg'
import frontpageImageTNav from '../../images/SkylabX_services_web.jpg'
import { Routing } from 'traficom-registry-shared'
import PageMeta from '../metadata/page-meta'

const StyledHeader = styled(H1)`
  color: white;
  font-size: 3rem;
  font-weight: 100;

  ${p => p.theme.mediaquery('sm')} {
    text-align: center;
    font-size: 7rem;
  }
`

const cards = [
  {
    titleKey: 'ng_title',
    contentKey: 'ng_content',
    imageSrc: frontpageImageNg,
    altTextKey: 'ng_image_alt',
    link: {
      labelKey: 'ng_link_label',
      to: Routing.patterns.droneRoot,
    },
  },
  {
    titleKey: 'tnav_title',
    contentKey: 'tnav_content',
    imageSrc: frontpageImageSkyLabX,
    altTextKey: 'tnav_image_alt',
    link: {
      labelKey: 'tnav_link_label',
    },
  },
  {
    titleKey: 'skylabx_title',
    contentKey: 'skylabx_content',
    imageSrc: frontpageImageTNav,
    altTextKey: 'skylabx_image_alt',
    link: {
      labelKey: 'skylabx_link_label',
    },
  },
]

const Frontpage: React.FunctionComponent = () => {
  const { t } = useTranslation('frontpage')

  return (
    <SectionWithBackground backgroundImageUrl={backgroundImage}>
      <PageMeta />
      <Container>
        <StyledHeader>SkyNavX</StyledHeader>
        <Row>
          {cards.map(({ titleKey, contentKey, imageSrc, altTextKey, link: { labelKey, to } }) => (
            <Col sm={4} key={titleKey}>
              <Card
                title={t(titleKey)}
                content={t(contentKey)}
                imageSrc={imageSrc}
                altText={t(altTextKey)}
                link={{
                  label: t(labelKey),
                  to,
                }}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </SectionWithBackground>
  )
}

export default Frontpage
