import { useField, useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { components } from 'react-select'
import { Country, Registrant } from 'traficom-registry-shared'
import useCodeset from '../../../hooks/use-codeset'
import { CheckboxField, FieldGroup, SelectField, TextField } from '../../../ui-common/form'
import { SelectComponents } from '../../../ui-common/form/select/react-select'
import InfoBox from '../../../ui-common/info-box/info-box'
import { Col, Row } from '../../../ui-common/layout/layout'
import { Comp } from '../../../utils/component'
import { Choice } from '../../../utils/types'

type Kind =
  /** Domestic address is not needed. Entered address must be outside EASA countries. */
  | 'non-easa'
  /** Address is always needed. Entered address must be either domestic, or outside EASA countries.  */
  | 'domestic-or-non-easa'
  /** Domestic address is not needed. Entered address can be any foreign address. */
  | 'foreign'
  /** Address is always needed, any country is allowed. */
  | 'any'

/** Namespace is restricted, if other values are used, the automatic country field assignment needs changes */
type AddressNamespace = 'address' | 'pilot.address'
type GroupProps = { addressNamespace?: AddressNamespace; kind: Kind; optional?: boolean }
type Props = { kind: 'domestic' | 'foreign'; namespace?: string; optional?: boolean }

const noteKeys: Record<Kind, string> = {
  any: 'address_note_any',
  'domestic-or-non-easa': 'address_note_domestic_or_non_easa',
  foreign: 'address_note_foreign',
  'non-easa': 'address_note_non_easa',
}

/**
 * Limit for post office options
 *
 * Something like 200 would show all options with any full post office name, but it's already too slow.
 */
const OPTION_LIMIT = 20

type WithAddress = { address: Registrant.Address }
type AddressValues = { pilot: WithAddress; address?: undefined } | (WithAddress & { pilot?: undefined })

export const AddressGroup: Comp<GroupProps> = ({ addressNamespace = 'address', kind, optional }) => {
  const { t } = useTranslation('personal_data')
  const { setFieldValue, values } = useFormikContext<{ hasForeignAddress: boolean } & AddressValues>()

  const countryField = getName(addressNamespace, 'country')
  const countryValue = values.pilot ? values.pilot.address.country : values.address.country
  const hasStreetValue = values.pilot ? values.pilot.address.street : values.address.street

  const needsAddress = kind === 'any' || kind === 'domestic-or-non-easa' || values.hasForeignAddress

  useEffect(() => {
    // When domestic address is set...
    if (!values.hasForeignAddress) {
      // ...set country to Finland if street field has a value, otherwise clear the country field
      setFieldValue(countryField, hasStreetValue ? 'FI' : '')
    }
    // When a foreign address is set...
    if (values.hasForeignAddress && countryValue === 'FI') {
      // ...clear the country field if value is Finland
      setFieldValue(countryField, '')
    }
  }, [countryField, setFieldValue, values.hasForeignAddress, hasStreetValue, countryValue])

  return (
    <FieldGroup title={t('address')}>
      <InfoBox>{t(noteKeys[kind])}</InfoBox>
      <CheckboxField label={t('foreign_address')} name="hasForeignAddress" />
      {needsAddress && (
        <AddressFields
          kind={values.hasForeignAddress ? 'foreign' : 'domestic'}
          namespace={addressNamespace}
          optional={optional}
        />
      )}
    </FieldGroup>
  )
}

const foreignCountries = Country.options.filter(c => c.value !== 'FI')

export const AddressFields: Comp<Props> = ({ kind, namespace, optional }) => {
  const { t } = useTranslation('personal_data')

  return (
    <>
      <TextField label={t('street_address')} name={getName(namespace, 'street')} optional={optional} />
      {kind === 'domestic' ? (
        <DomesticPostalCodeField namespace={namespace} optional={optional} />
      ) : (
        <>
          <TextField label={t('postal_code')} name={getName(namespace, 'postalCode')} optional={optional} />
          <TextField label={t('municipality')} name={getName(namespace, 'municipality')} optional={optional} />
        </>
      )}
      {kind === 'foreign' && (
        <SelectField
          label={t('country')}
          name={getName(namespace, 'country')}
          optional={optional}
          options={foreignCountries}
        />
      )}
    </>
  )
}

const DomesticPostalCodeField: Comp<{ namespace?: string; optional?: boolean }> = props => {
  const { namespace, optional } = props

  const { t } = useTranslation('personal_data')

  const postalOffice = useCodeset('Postitoimipaikat')

  const { setFieldValue } = useFormikContext()
  const [postalCode] = useField(getName(namespace, 'postalCode'))

  useEffect(() => {
    setFieldValue(
      getName(namespace, 'municipality'),
      postalOffice.values.find(c => c.value === postalCode.value)?.label ?? '',
    )
  }, [namespace, postalCode.value, setFieldValue, postalOffice.values])

  const loadOptions = (value: string) =>
    postalOffice.values.filter(matchPostalOffice(value.toUpperCase())).slice(0, OPTION_LIMIT)

  return (
    <SelectField
      components={{ Option: PostalCodeRow, SingleValue: PostalCodeValue }}
      label={t('postal_district')}
      name={getName(namespace, 'postalCode')}
      noOptionsMessage={({ inputValue }) => t(inputValue ? 'common:no_options' : 'enter_postal_code_district_start')}
      optional={optional}
      options={loadOptions}
    />
  )
}

const PostalCodeRow: SelectComponents['Option'] = props => (
  <components.Option {...props}>
    <Row>
      <Col xs={3}>{props.data.value}</Col>
      <Col xs={9}>{props.label}</Col>
    </Row>
  </components.Option>
)

const PostalCodeValue: SelectComponents['SingleValue'] = props => (
  <components.SingleValue {...props}>
    {props.data.value} {props.data.label}
  </components.SingleValue>
)

const matchPostalOffice = (value: string) => (choice: Choice) => choice.value.includes(value) || choice.label.includes(value)

const getName = (namespace: string | undefined, name: keyof Registrant.Address): string =>
  namespace ? `${namespace}.${name}` : name
