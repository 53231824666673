import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Feature, Locale, Routing } from 'traficom-registry-shared'
import { isDuring } from '../../services/announcement-service'
import { RegistrationContext } from '../../state/registration'
import { theme } from '../../styling/theme'
import { Alert } from '../../ui-common/alert/alert'
import Hero from '../../ui-common/hero/hero'
import { LinkItem } from '../../ui-common/hero/hero'
import PageMeta from '../metadata/page-meta'
import { Col, Container, Row } from '../../ui-common/layout/layout'
import { SectionWithBackgroundGradient } from '../../ui-common/section/SectionWithBackground'
import Card from '../../ui-common/card/card'
import registerOperatorImage from '../../images/drone_pilot_03_web.jpg'
import pilotInfoChangeImage from '../../images/drone_pilot_info_change.jpg'
import operatorInfoChangeImage from '../../images/drone_operator_update_web.jpg'
import pilotTestImage from '../../images/drone_pilot_test_web.jpg'
import operatorExtensionImage from '../../images/drone_extend_operator_web.jpg'
import styled from 'styled-components'

const links: LinkItem[] = [
  {
    labelKey: 'registration:read_more_about_drone_services',
    to: Routing.patterns.droneInfo,
    variant: 'secondary',
  },
]

type Card = {
  titleKey: string
  contentKey: string
  imageSrc: string
  link: LinkItem
  feature?: Feature.Name
}

const allCards: Card[] = [
  {
    titleKey: 'ng_frontpage:register_as_operator',
    contentKey: 'ng_frontpage:register_as_operator_content',
    imageSrc: registerOperatorImage,
    link: {
      labelKey: 'ng_frontpage:go_to_registration',
      to: Routing.getRolePath('register', 'operator'),
    },
  },
  {
    titleKey: 'registration:pilot_info_change',
    contentKey: 'registration:pilot_info_change_description',
    imageSrc: pilotInfoChangeImage,
    link: {
      labelKey: 'registration:change_info',
      to: Routing.getRolePath('update', 'pilot'),
    },
  },
  {
    titleKey: 'registration:operator_info_change',
    contentKey: 'registration:operator_info_change_description',
    imageSrc: operatorInfoChangeImage,
    link: {
      labelKey: 'registration:change_info',
      to: Routing.getRolePath('update', 'operator'),
    },
  },
  {
    titleKey: 'registration:period_extension',
    contentKey: 'registration:operator_period_extension_info',
    imageSrc: operatorExtensionImage,
    link: {
      labelKey: 'ng_frontpage:go_to_registration',
      to: Routing.getRolePath('extend', 'operator'),
    },
  },
  {
    titleKey: 'ng_frontpage:pilot_test_title',
    contentKey: 'ng_frontpage:pilot_test_content',
    imageSrc: pilotTestImage,
    link: {
      labelKey: 'ng_frontpage:sign_up',
      to: Routing.getMainPath('register', 'pilot', 'independent'),
    },
  },
]

const BackgroundSectionWithGradient = styled(SectionWithBackgroundGradient)`
  padding-top: ${p => p.theme.spacing(3)};
  padding-bottom: ${p => p.theme.spacing(3)};

  ${p => p.theme.mediaquery('sm')} {
    padding-top: ${p => p.theme.spacing(3)};
    padding-bottom: ${p => p.theme.spacing(3)};
  }
`

const NGFrontpage: React.FunctionComponent = () => {
  const { i18n, t } = useTranslation()

  const { features, announcements } = useContext(RegistrationContext).state
  const cards = allCards.filter(c => c.feature === undefined || features[c.feature as Feature.Name])
  const unavailable = announcements.values.find(a => a.unavailable && isDuring(new Date(), a.unavailable))

  return (
    <BackgroundSectionWithGradient angle={135} gradientStart={theme.palette.grey[300]} gradientEnd={theme.palette.grey[500]}>
      <PageMeta title={t('ng_frontpage:hero_title')} />
      <Hero fullPage title={t('ng_frontpage:hero_title')} introduction={t('ng_frontpage:introduction')} links={links} />
      <Container>
        {unavailable ? (
          <Alert {...unavailable.content[i18n.language as Locale.UserLanguage]} />
        ) : (
          <Row>
            {cards.map(({ titleKey, contentKey, imageSrc, link: { labelKey, to } }) => (
              <Col sm={6} key={titleKey}>
                <Card
                  title={t(titleKey)}
                  content={t(contentKey)}
                  imageSrc={imageSrc}
                  link={{
                    label: t(labelKey),
                    to,
                  }}
                />
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </BackgroundSectionWithGradient>
  )
}

export default NGFrontpage
