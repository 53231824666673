import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Button from '../button/button'
import { Container } from '../layout/layout'
import { Markdown } from '../markdown'
import { H1 } from '../typography/heading'

export type LinkItem = {
  labelKey: string
  to: string
  variant?: 'primary' | 'secondary'
}

export type HeroProps = {
  title: string
  introduction: string
  className?: string
  fullPage?: boolean
  links?: LinkItem[]
}

const HeroCard = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
  box-shadow: 0 0.25rem 1rem rgba(48, 55, 66, 0.15);
  margin-bottom: ${p => p.theme.spacing(1)};
  padding: ${p => p.theme.spacing(2)};
  ${p => p.theme.mediaquery('sm')} {
    padding: ${p => p.theme.spacing(3)};
  }
  h1 {
    color: ${props => props.theme.palette.text.dark};
    ${p => p.theme.mediaquery('sm')} {
      font-size: 5em;
    }
  }
`

const Ingress = styled(Markdown)`
  color: ${props => props.theme.palette.text.dark};
  margin-top: 0;
  font-size: 18px;
  ${p => p.theme.mediaquery('sm')} {
    font-size: 22px;
  }
`

const Hero: React.FunctionComponent<HeroProps> = props => {
  const { title, introduction, links } = props
  const { t } = useTranslation()
  return (
    <Container>
      <HeroCard>
        <H1>{title}</H1>
        <Ingress>{introduction}</Ingress>
        {links && (
          <>
            {links.map(({ to, labelKey, variant }) => (
              <Button key={labelKey} as={Link} to={to} variant={variant}>
                {t(labelKey)}
              </Button>
            ))}
          </>
        )}
      </HeroCard>
    </Container>
  )
}

export default Hero
