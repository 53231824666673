import { useFormikContext } from 'formik'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Registration, Routing } from 'traficom-registry-shared'
import { CancelButton, CheckboxField, FieldGroup, Section, SubmitButton } from '../../ui-common/form'
import { Col, Row } from '../../ui-common/layout/layout'
import { Comp } from '../../utils/component'
import { FormValues } from '../../utils/types'
import { RegistrationPeriod } from './fields'
import { Extend, RegisterOrganization, RegisterPerson } from './operator-data'
import { Independent } from './pilot-data'

type Props = {
  mode: 'extend' | 'register-organization' | 'register-person'
  showEmailConfirm?: boolean
}
type PilotPaymentProps = { showEmailConfirm?: boolean }
type Values = FormValues<Extend | RegisterOrganization | RegisterPerson>

export const PeriodAndPayment: Comp<Props> = ({ mode, showEmailConfirm }) => {
  const { t } = useTranslation('personal_data')
  const { values } = useFormikContext<Values>()

  const getEmail = (values: Values): string => ('contact' in values ? values.contact.email : values.email)

  // TODO: Change title / structure
  return (
    <Section title={t('registration_period')}>
      {mode === 'extend' && <p>{t('registration:registration_period_extension_info')}</p>}
      <RegistrationPeriod />
      <hr />
      <PaymentNote cost={values.registrationPeriod ? Registration.periodCosts[values.registrationPeriod] : undefined} />
      <hr />
      {mode !== 'extend' && (
        <FieldGroup>
          <CheckboxField label={t('confirmation_of_competence')} name="confirmationOfCompetence" />
        </FieldGroup>
      )}
      {mode === 'register-organization' && (
        <FieldGroup>
          <CheckboxField label={t('have_signing_authority')} name="signingAuthority" />
        </FieldGroup>
      )}
      {showEmailConfirm && (
        <p>
          <Trans i18nKey="personal_data:register_email_confirm_info">
            Rekisteröintiin liittyvät dokumentit toimitetaan sähköpostiosoitteeseen
            <strong>{{ email: getEmail(values) }}</strong>. Tarkista sähköpostin oikeellisuus.
          </Trans>
        </p>
      )}
      <Buttons />
    </Section>
  )
}

export const PilotPayment: Comp<PilotPaymentProps> = ({ showEmailConfirm }) => {
  const { values } = useFormikContext<Independent>()

  return (
    <Section>
      <hr />
      <PaymentNote cost={Registration.pilotTestCost} />
      <hr />
      {showEmailConfirm && (
        <p>
          <Trans i18nKey="personal_data:pilot_test_email_confirm_info">
            Pilottikokeeseen liittyvät dokumentit toimitetaan sähköpostiosoitteeseen
            <strong>{{ email: values.email }}</strong>. Tarkista sähköpostin oikeellisuus.
          </Trans>
        </p>
      )}
      <Buttons />
    </Section>
  )
}

const PaymentNote: Comp<{ cost: number | undefined }> = ({ cost }) => {
  const { t } = useTranslation()

  return (
    <Row>
      <Col xs={8}>{t('personal_data:amount_to_pay')}</Col>
      <Col align="end" xs={4}>
        {cost ? `${cost} €` : ''}
      </Col>
    </Row>
  )
}

const Buttons: Comp = () => (
  <>
    <SubmitButton labelKey="personal_data:to_payment" />
    <CancelButton to={Routing.getStepPath('choose-identification')} />
  </>
)
