import React from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { Registration, Routing } from 'traficom-registry-shared'
import { Comp } from '../../utils/component'
import { Page } from '../layout'
import { PaymentFinish } from '../payment/payment-finish'
import { EnterInfo } from './enter-info'
import { Identification } from './identification'
import { IdentifyStrong } from './identify-strong'
import { StartVerification } from './start-verification'
import { UpdateFinish } from './update-finish'
import { VerificationCode } from './verification-code'
import { ErrorState } from '../error/error-page'

export const RegisterMain: React.FC<Registration.Context> = props => {
  const { t } = useTranslation()
  const match = useRouteMatch()

  return (
    <Page.Content title={t(Registration.getTitlePhrase(props))}>
      <Switch>
        <Redirect path={match.path} to={`${match.path}/${Routing.getStepPath('choose-identification')}`} exact />
        <Route path={`${match.path}/${Routing.patterns.step}`} render={() => <StepRoutes {...props} />} exact />
        <Route path={`${match.path}/${Routing.patterns.orderStatus}`} component={PaymentFinish} />
        <Route render={() => <ErrorState error="not-found" />} />
      </Switch>
    </Page.Content>
  )
}

const StepRoutes: React.FC<Registration.Context> = props => {
  const { step } = useParams<{ step: Registration.Step }>()
  if (!Registration.steps.includes(step)) {
    return <ErrorState error="not-found" />
  }

  const Comp = stepComponents[step]

  return <Comp {...props} />
}

const Completed: Comp<Registration.Context> = ({ action }) =>
  action === 'update' ? <UpdateFinish /> : <ErrorState error="not-found" />

const stepComponents: Record<Registration.Step, React.FC<Registration.Context>> = {
  'choose-identification': Identification,
  'identify-strong': IdentifyStrong,
  'send-email': StartVerification,
  'confirm-email': VerificationCode,
  'enter-info': EnterInfo,
  completed: Completed,
}
