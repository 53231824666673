import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Registration } from 'traficom-registry-shared'
import { AuthService } from '../../services/auth-service'
import { actions, RegistrationContext } from '../../state/registration'
import { Comp } from '../../utils/component'
import { OperatorExtend } from './operator-extend'
import { OperatorOrganizationInfo } from './operator-organization-info'
import { OperatorOrganizationUpdate } from './operator-organization-update'
import { OperatorPersonInfo } from './operator-person-info'
import { OperatorPersonUpdate } from './operator-person-update'
import { PilotIndependentInfo } from './pilot-independent-info'
import { PilotOperatorInfo } from './pilot-operator-info'
import Loader from '../../ui-common/loader/loader'
import { ErrorState } from '../error/error-page'
import { PilotUpdate } from './pilot-update'

type LoginFetchState = 'failed' | 'fetched' | 'not-fetched' | 'not-used'

const useIdentification = (initial: LoginFetchState) => {
  const { state, dispatch } = useContext(RegistrationContext)
  const { identification } = state
  const [loginFetchState, setLoginFetchState] = useState(initial)

  useEffect(() => {
    const fetchLoginUrl = async () => {
      const result = await AuthService.fetchUserInfo()
      setLoginFetchState(result.success ? 'fetched' : 'failed')
      if (result.success) {
        dispatch(actions.identify(result.data))
      }
    }

    if (loginFetchState === 'not-fetched') {
      fetchLoginUrl()
    }
  }, [dispatch, loginFetchState])

  return { identification, loginFetchState }
}

export const EnterInfo: Comp<Registration.Context> = context =>
  context.action === 'register' ? (
    <RegisterForm {...context} />
  ) : context.action === 'extend' ? (
    <ExtendForm {...context} />
  ) : (
    <UpdateForm {...context} />
  )

const ExtendForm: Comp<Registration.Context & { action: 'extend' }> = ({ operatorKind }) => {
  const { t } = useTranslation()
  const { identification, loginFetchState } = useIdentification(operatorKind === 'organization' ? 'not-used' : 'not-fetched')

  if (loginFetchState === 'not-fetched') {
    return <Loader text={t('common:loading')} />
  }

  return identification.identified && identification.mode === 'STRONG' ? (
    <OperatorExtend mode="STRONG" identification={identification.data} operatorKind={operatorKind} />
  ) : (
    <OperatorExtend mode="NONE" operatorKind={operatorKind} />
  )
}

const RegisterForm: Comp<Registration.Context & { action: 'register' }> = p => {
  const { t } = useTranslation()
  const { identification, loginFetchState } = useIdentification(
    p.role === 'pilot' && p.operatorKind === 'organization' ? 'not-used' : 'not-fetched',
  )

  if (loginFetchState === 'failed') {
    return <ErrorState error="generic" />
  } else if (loginFetchState === 'not-fetched') {
    return <Loader text={t('common:loading')} />
  }

  if (p.role === 'pilot') {
    if (p.operatorKind === 'organization') {
      return <PilotOperatorInfo operatorKind="organization" />
    } else if (p.operatorKind === 'person' && identification.identified && identification.mode === 'STRONG') {
      return <PilotOperatorInfo operatorKind="person" credentials={identification.data} />
    } else if (p.operatorKind === 'independent' && identification.identified) {
      return <PilotIndependentInfo identification={identification} />
    }
  }

  if (p.role === 'operator' && identification.identified) {
    return p.operatorKind === 'person' ? (
      <OperatorPersonInfo identification={identification} />
    ) : (
      <OperatorOrganizationInfo identification={identification} />
    )
  }

  return <ErrorState error="not-found" />
}

const UpdateForm: Comp<Registration.Context & { action: 'update' }> = ({ operatorKind, role }) => {
  const { t } = useTranslation()
  const { identification, loginFetchState } = useIdentification('not-fetched')

  if (loginFetchState === 'not-fetched') {
    return <Loader text={t('common:loading')} />
  }

  const Comp = role === 'pilot' ? PilotUpdate : operatorKind === 'person' ? OperatorPersonUpdate : OperatorOrganizationUpdate

  return identification.identified && identification.mode === 'STRONG' ? (
    <Comp mode="STRONG" identification={identification.data} />
  ) : (
    <Comp mode="NONE" />
  )
}
