import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { Payment } from 'traficom-registry-shared'
import { api } from '../../services/api'
import { Box, Button, Col, Heading, Paragraphs, Row } from '../../ui-common'
import EmptyState from '../../ui-common/empty-state/empty-state'
import Loader from '../../ui-common/loader/loader'
import { Comp } from '../../utils/component'
import { ErrorState } from '../error/error-page'
import PageMeta from '../metadata/page-meta'
import { ReactComponent as FintrafficLogoHorizontalBlack } from '../../images/Fintraffic_logo_horizontal_black.svg'

type UiState = {
  loading: boolean
  payment?: Payment.Details
}

export const PaymentFinish: Comp = () => {
  const { orderNumber } = useParams<{ orderNumber: string }>()
  const { t } = useTranslation('payment')
  const [uiState, setUiState] = useState<UiState>({ loading: true })

  useEffect(() => {
    const fetchStatus = async () => {
      const result = await api.orderStatus(undefined, { orderNumber })
      const payment = result.success ? result.data : undefined
      setUiState({ payment, loading: false })
    }
    fetchStatus()
  }, [orderNumber])

  return uiState.loading ? (
    <Loader text={t('loading')} />
  ) : uiState.payment ? (
    <Content payment={uiState.payment} />
  ) : (
    <ErrorState error="not-found" />
  )
}

const Content: Comp<{ payment: Payment.Details }> = ({ payment }) => {
  const match = useRouteMatch()

  return (
    <Switch>
      <Redirect path={match.path} to={`${match.path}/finished`} exact />
      <Route path={`${match.path}/finished`} exact>
        <Main status={payment.status} />
      </Route>
      <Route path={`${match.path}/receipt`} exact>
        <Receipt payment={payment} />
      </Route>
    </Switch>
  )
}

const Main: Comp<{ status: Payment.Status }> = ({ status }) => {
  const { t } = useTranslation('payment')

  return (
    <>
      <PageMeta subTitle={t(headerKeys[status])} />
      <EmptyState state={status === 'SUCCESS' ? 'success' : 'error'} title={t(headerKeys[status])}>
        <Paragraphs>{t(bodyKeys[status])}</Paragraphs>
        {status === 'SUCCESS' && (
          <Button $fullWidth as={Link} to="receipt">
            {t('show_receipt')}
          </Button>
        )}
      </EmptyState>
    </>
  )
}

const Receipt: Comp<{ payment: Payment.Details }> = ({ payment }) => {
  const { t } = useTranslation('payment')

  if (payment.status !== 'SUCCESS') {
    return <ErrorState error="not-found" />
  }
  const date = new Date(payment.paidAt).toLocaleDateString('fi')
  const priceFormatter = Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR' })

  return (
    <ReceiptBox>
      <ReceiptWrap>
        <Row>
          <Col xs={6}>
            <Heading.H2>{t('receipt_title_short')}</Heading.H2>
          </Col>
          <Col align="end" xs={6}>
            <Heading.H2>{date}</Heading.H2>
          </Col>
        </Row>
        <pre style={{ whiteSpace: 'normal' }}>{t('identifier_note', { code: payment.orderNumber })}</pre>
        <hr />
        {payment.products.map((product, i) => (
          <Row key={i}>
            <Col sm={10}>
              <pre style={{ whiteSpace: 'normal' }}>{product.title}</pre>
            </Col>
            <Col sm={2} align="end">
              <pre>{priceFormatter.format(parseFloat(product.price))}</pre>
            </Col>
          </Row>
        ))}
        <Row>
          <Col xs={8}>
            <pre style={{ marginBottom: '0px' }}>
              <b>{t('receipt_in_total')}</b>
            </pre>
          </Col>
          <Col align="end" xs={4}>
            <pre style={{ marginBottom: '0px' }}>
              {priceFormatter.format(payment.products.map(p => parseFloat(p.price)).reduce((sum, price) => sum + price, 0))}
            </pre>
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <pre style={{ marginTop: '0px' }}>{t('vat')} (0%)</pre>
          </Col>
          <Col align="end" xs={4}>
            <pre style={{ marginTop: '0px' }}>{priceFormatter.format(0)}</pre>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <pre style={{ whiteSpace: 'pre-wrap' }}>{t('receipt_contact_address')}</pre>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <pre style={{ margin: '0px' }}>{t('receipt_contact_business_id')}</pre>
          </Col>
          <Col sm={8}>
            <FintrafficLogoHorizontalBlack height={'40px'} />
          </Col>
        </Row>
        <Row>
          <Col>
            <pre style={{ whiteSpace: 'pre-wrap' }}>{t('receipt_contact_phone_email')}</pre>
          </Col>
        </Row>
      </ReceiptWrap>
      <Row>
        <Col sm={6}>
          <Button $fullWidth onClick={window.print}>
            {t('print_or_save')}
          </Button>
        </Col>
        <Col sm={6}>
          <Button $fullWidth as={Link} to="finished" variant="secondary">
            {t('common:go_back')}
          </Button>
        </Col>
      </Row>
    </ReceiptBox>
  )
}

const ReceiptBox = styled(Box)`
  @media print {
    visibility: hidden;
  }
`

// Only print allowed area, based on https://stackoverflow.com/a/2618980
const ReceiptWrap = styled.div`
  @media print {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
    background-color: white;
  }
`

const headerKeys: Record<Payment.Status, string> = {
  CANCEL: 'cancel.header',
  PENDING: 'pending.header',
  SUCCESS: 'success.header',
}

const bodyKeys: Record<Payment.Status, string> = {
  CANCEL: 'cancel.body',
  PENDING: 'pending.body',
  SUCCESS: 'success.body',
}
