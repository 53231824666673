import React from 'react'
import { useTranslation } from 'react-i18next'
import { Locale, validators } from 'traficom-registry-shared'
import { api } from '../../services/api'
import { Form, Section, Submit, TextField, useFormErrorHandler } from '../../ui-common/form'
import { Comp } from '../../utils/component'
import { Hello, IdentificationFields } from './fields'
import { Extend, extendDefaults, ExtendProps } from './operator-data'
import { PeriodAndPayment } from './period-payment-fields'

export const OperatorExtend: React.FC<ExtendProps> = props => {
  const { i18n } = useTranslation()

  const handleError = useFormErrorHandler<Extend>()

  const submit: Submit<Extend> = async (values, helpers) => {
    const result = await api.extendOperator({ ...values, userLanguage: i18n.language as Locale.UserLanguage })
    if (result.success) {
      window.location.href = result.data.url
    } else {
      handleError(result.data, helpers)
    }
  }

  return (
    <Form<Extend>
      key={props.mode} // Needed to reset form values properly if the user changes identification method
      initialValues={extendDefaults(props)}
      onSubmit={submit}
      validate={validators.extendOperator}
    >
      <CredentialSection {...props} />
      <PeriodAndPayment mode="extend" showEmailConfirm={true} />
    </Form>
  )
}

const CredentialSection: Comp<ExtendProps> = props => {
  const { t } = useTranslation()

  return (
    <Section>
      {props.mode === 'STRONG' && <Hello person={props.identification} />}
      <p>{t('personal_data:operator_extend_description')}</p>
      <IdentificationFields enableChangeIdentifier={props.mode === 'NONE'} role="operator" />
      <TextField label={t('personal_data:email')} name="email" />
    </Section>
  )
}
