import React from 'react'
import { useTranslation } from 'react-i18next'
import { Registration } from 'traficom-registry-shared'
import { TextField } from '../../../ui-common/form'
import { Comp } from '../../../utils/component'

type Props = { enableChangeIdentifier: boolean; role: Registration.Role }

export const IdentificationFields: Comp<Props> = ({ enableChangeIdentifier, role }) => {
  const { t } = useTranslation('registration')

  return (
    <>
      {role === 'operator' && (
        <>
          <p>{t(enableChangeIdentifier ? 'operator_change_note_unidentified' : 'operator_change_note_identified')}</p>
          <p>{t('operator_identifier_format_note')}</p>
          <TextField
            label={t('operator_identifier')}
            name="operatorIdentifier"
            placeholder={t('common:for_example', { value: 'FIN123456789012a' })}
          />
        </>
      )}
      {role === 'pilot' && (
        <>
          <p>{t(enableChangeIdentifier ? 'pilot_change_note_unidentified' : 'pilot_change_note_identified')}</p>
          <TextField
            label={t('pilot_identifier')}
            name="pilotIdentifier"
            placeholder={t('common:for_example', { value: 'FIN-RP-123456789012' })}
          />
        </>
      )}
      {enableChangeIdentifier && (
        <TextField
          label={t('change_identifier')}
          name="changeIdentifier"
          placeholder={t('common:for_example', { value: '12345678-90ab-...' })}
        />
      )}
    </>
  )
}
