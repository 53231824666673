import { Form, Formik } from 'formik'
import React, { useContext, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import { Routing, validators } from 'traficom-registry-shared'
import { api } from '../../services/api'
import { actions, RegistrationContext } from '../../state/registration'
import { FormSubmitStatus, Section, Submit, SubmitButton, TextField } from '../../ui-common/form'
import { H3 } from '../../ui-common/typography/heading'
import PageMeta from '../metadata/page-meta'

type Values = { email: string; code: string }

export const VerificationCode: React.FC = () => {
  const { i18n, t } = useTranslation('registration')
  const { state, dispatch } = useContext(RegistrationContext)
  const [uiState, setUiState] = useState(FormSubmitStatus.NOT_SENT)

  const recaptchaRef = useRef<ReCAPTCHA>(null)

  const submit: Submit<Values> = async data => {
    const recaptchaValue = await recaptchaRef.current?.executeAsync()

    if (!recaptchaValue) {
      // What should we do in this case?
      setUiState(FormSubmitStatus.ERROR)
      return
    }

    const result = await api.verifyEmail(data, { recaptchaValue })

    dispatch(actions.identify({ mode: 'EMAIL', data, identified: result.success }))
    setUiState(result.success ? FormSubmitStatus.SUCCESS : FormSubmitStatus.ERROR)

    if (!result.success) {
      recaptchaRef.current?.reset()
    }
  }

  const initialValues = {
    email: state.identification.mode === 'EMAIL' ? state.identification.data.email ?? '' : '',
    code: '',
  }

  const title = t('verification_code_from_email')

  return (
    <Section title={title}>
      <PageMeta subTitle={title} />
      <p>{t('verification_code_ingress')}</p>
      <Formik initialValues={initialValues} onSubmit={submit} validate={validators.verifyEmail}>
        <Form>
          <TextField label={t('email')} name="email" />
          <TextField label={t('verification_code')} name="code" />
          <SubmitButton disabled={!state.recaptchaKey} labelKey="registration:pass_code" />
          {state.recaptchaKey && (
            <ReCAPTCHA hl={i18n.language} ref={recaptchaRef} sitekey={state.recaptchaKey} size="invisible" />
          )}
        </Form>
      </Formik>
      {uiState === FormSubmitStatus.SUCCESS && <Redirect push to={Routing.getStepPath('enter-info')} />}
      {uiState === FormSubmitStatus.ERROR && <H3>{t('common:errors.check_verification_code')}</H3>}
    </Section>
  )
}
