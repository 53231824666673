import { Identification, Registrant } from 'traficom-registry-shared'
import { FormValues } from '../../utils/types'
import { address, consents, personWithConsents, UpdateAuthMode } from './operator-data'

export type Independent = Registrant.IndependentPilotBase & { hasForeignAddress: boolean }

export type RegisterInitial =
  | { operatorKind: 'organization' }
  | { operatorKind: 'person'; credentials: Identification.StrongData }

export type Register = Registrant.OperatorPilot & { hasForeignAddress: boolean }

export type Update = Registrant.UpdatePilot & { hasForeignAddress: boolean }

const pilot: FormValues<Registrant.PilotDetails> = { firstNames: '', lastName: '', ssn: '', email: '', phone: '', address }

const base = {
  operatorIdentifier: '',
  pilot,
  parentalConfirmation: false,
  hasForeignAddress: false,
} as const

const contact = {
  email: '',
  phone: '',
  address,
  hasForeignAddress: false,
} as const

export const independentDefaults = (initial: Identification.IdentifiedState): FormValues<Independent> =>
  initial.mode === 'STRONG' ? strongDefaults : emailDefaults(initial.data.email)

const strongDefaults: FormValues<Independent> & { authType: 'STRONG' } = { authType: 'STRONG', ...contact }

const emailDefaults = (email: string): FormValues<Independent> & { authType: 'EMAIL' } => ({
  authType: 'EMAIL',
  ...personWithConsents,
  ...contact,
  email,
  language: '',
})

export const registerDefaults = (initial: RegisterInitial): FormValues<Register> =>
  initial.operatorKind === 'person' ? { ...base, ...initial } : { ...base, ...initial, changeIdentifier: '' }

export const resetRegister = (values: Registrant.OperatorPilot): FormValues<Register> => ({
  ...values,
  pilot,
  parentalConfirmation: false,
  hasForeignAddress: false,
})

export const updateDefaults = (authType: UpdateAuthMode): FormValues<Update> => ({
  authType,
  pilotIdentifier: '',
  changeIdentifier: '',
  email: '',
  phone: '',
  address,
  hasForeignAddress: false,
  language: '',
  consents,
})
