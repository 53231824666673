import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Codeset, Locale } from 'traficom-registry-shared'
import { api } from '../services/api'
import { setCodesets } from '../state/registration/actions'
import { CodesetData, RegistrationContext } from '../state/registration'

const useCodeset = (codeset: Codeset.Name): CodesetData => {
  const { state, dispatch } = useContext(RegistrationContext)
  const language = useTranslation().i18n.language as Locale.UserLanguage
  const data = state.codesets[language][codeset] ?? { values: [], status: 'NOT_LOADED' }

  useEffect(() => {
    const fetchCodeset = async () => {
      if (data.status === 'NOT_LOADED') {
        dispatch(setCodesets(codeset, 'PENDING', [], language))
        const result = await api.codeset(undefined, { codesetName: codeset, lang: language })
        if (result.success) {
          const options = result.data.map(v => ({ value: v.code, label: v.text }))
          dispatch(setCodesets(codeset, 'LOADED', options, language))
        } else {
          dispatch(setCodesets(codeset, 'FAILED', [], language))
        }
      }
    }

    fetchCodeset()
  }, [data.status, codeset, language, dispatch])

  return data
}

export default useCodeset
