import { useFormikContext } from 'formik'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import { Routing, validators } from 'traficom-registry-shared'
import { api } from '../../services/api'
import { CancelButton, Form, Section, Submit, SubmitButton, useFormErrorHandler } from '../../ui-common/form'
import { Comp } from '../../utils/component'
import { AddressGroup, ConsentFields, ContactFields, Hello, IdentificationFields, LanguageField } from './fields'
import { UpdateAuthMode, UpdateProps } from './operator-data'
import { Update, updateDefaults } from './pilot-data'

export const PilotUpdate: Comp<UpdateProps> = props => {
  const [succeeded, setSucceeded] = useState(false)

  const handleError = useFormErrorHandler<Update>()

  const submit: Submit<Update> = async (values, helpers) => {
    const result = await api.updatePilot(values)

    if (result.success) {
      setSucceeded(true)
    } else {
      handleError(result.data, helpers)
    }
  }

  if (succeeded) {
    return <Redirect push to={Routing.getStepPath('completed')} />
  }

  return (
    <Form<Update>
      key={props.mode} // Needed to reset form values properly if the user changes identification method
      initialValues={updateDefaults(props.mode)}
      onSubmit={submit}
      validate={validators.updatePilot}
    >
      <CredentialSection {...props} />
      <PilotSection mode={props.mode} />
    </Form>
  )
}

const CredentialSection: Comp<UpdateProps> = props => {
  const { t } = useTranslation()

  return (
    <Section>
      {props.mode === 'STRONG' && <Hello person={props.identification} />}
      <p>{t('personal_data:person_update_description')}</p>
      <IdentificationFields enableChangeIdentifier={props.mode === 'NONE'} role="pilot" />
    </Section>
  )
}

const PilotSection: Comp<{ mode: UpdateAuthMode }> = ({ mode }) => {
  const { t } = useTranslation()
  const { values } = useFormikContext<Update>()

  return (
    <>
      <Section title={t('personal_data:personal_data')}>
        <ContactFields optional />
        <AddressGroup kind="any" optional />
        {mode === 'NONE' && <LanguageField />}
      </Section>
      {mode === 'NONE' && (
        <Section>
          <ConsentFields namespace="consents" />
        </Section>
      )}
      <Section>
        {values.email ? (
          <p>
            <Trans i18nKey="personal_data:update_data_email_confirm_info">
              Vahvistus tietojen muutoksesta toimitetaan sähköpostiosoitteeseen <strong>{{ email: values.email }}</strong>.
              Tarkista sähköpostin oikeellisuus.
            </Trans>
          </p>
        ) : (
          <p>{t('personal_data:update_pilot_same_email')}</p>
        )}
        <SubmitButton labelKey="registration:send_updated_info" />
        <CancelButton to={Routing.patterns.droneRoot} />
      </Section>
    </>
  )
}
