import React from 'react'
import { useTranslation } from 'react-i18next'
import { Registration, Routing } from 'traficom-registry-shared'
import { ButtonLinkMenu, LinkItem, Paragraphs } from '../../ui-common'
import { Section } from '../../ui-common/form'
import { Page } from '../layout'
import { RegisterMain } from './register-main'

type Props = { action: Registration.Action; role: Registration.Role }

export const RoleRootSelect: React.FC<Props> = props => {
  const { action, role } = props
  const { t } = useTranslation()

  if (action === 'update' && role === 'pilot') {
    return <RegisterMain action="update" role="pilot" />
  }

  const noteKey =
    action === 'register' && role === 'pilot' ? 'registration:operator_kind_note_pilot' : 'registration:operator_kind_note'
  const subTitle = t('registration:operator_kind_title')

  return (
    <Page.Content title={t(Registration.getTitlePhrase(props))} subTitle={subTitle}>
      <Section title={subTitle}>
        <Paragraphs>{t(noteKey)}</Paragraphs>
        <ButtonLinkMenu items={getOptions(props)} />
      </Section>
    </Page.Content>
  )
}

const getOptions = ({ action, role }: Props): LinkItem[] => [
  {
    labelKey:
      action === 'register' && role === 'pilot' ? 'registration:strong_identification' : 'registration:private_person',
    to: Routing.getMainPath(action, role, 'person'),
  },
  {
    labelKey:
      action === 'register' && role === 'pilot' ? 'registration:on_behalf_of_organization' : 'registration:organization',
    to: Routing.getMainPath(action, role, 'organization'),
  },
]
