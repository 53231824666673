import React from 'react'
import { useTranslation } from 'react-i18next'
import droneWater from '../../images/Drone_water_web.jpeg'
import './oosStyles.css'

function OutOfService() {
  const { t } = useTranslation()
  return (
    <div
      className="bg"
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '50vw',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        position: 'relative',
        left: '25%',
        margin: '48px 0 48px 0',
        paddingTop: '16px',
        paddingBottom: '48px',
      }}
    >
      <div
        className="bg"
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '32px' }}
      >
        <h1>{t('ng_frontpage:hero_title')}</h1>

        <div style={{ marginLeft: '48px', marginRight: '48px', marginBottom: '48px' }}>{t('oos')}</div>

        <img src={droneWater} style={{ width: '100%' }} />
      </div>
    </div>
  )
}

export default OutOfService
