import { useFormikContext } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Identification, Locale, validators } from 'traficom-registry-shared'
import { api } from '../../services/api'
import { Form, RadioGroupBooleanField, Section, Submit, useFormErrorHandler } from '../../ui-common/form'
import { Comp } from '../../utils/component'
import { FormValues } from '../../utils/types'
import { AddressGroup, ConsentFields, ContactFields, InsuranceField, LanguageField, PersonFields } from './fields'
import { personDefaults, RegisterPerson } from './operator-data'
import { PeriodAndPayment } from './period-payment-fields'

type Props = { identification: Identification.IdentifiedState }

export const OperatorPersonInfo: React.FC<Props> = ({ identification }) => {
  const { i18n } = useTranslation()

  const handleError = useFormErrorHandler<RegisterPerson>()

  const submit: Submit<RegisterPerson> = async (values, helpers) => {
    const userLanguage = i18n.language as Locale.UserLanguage
    const result = await api.registerOperator({ operatorKind: 'person', userLanguage, ...values })
    if (result.success) {
      window.location.href = result.data.url
    } else {
      handleError(result.data, helpers)
    }
  }

  return (
    <Form<RegisterPerson>
      key={identification.mode} // Needed to reset form values properly if the user changes identification method
      initialValues={personDefaults(identification)}
      onSubmit={submit}
      validate={validators.operatorPerson}
    >
      <PersonalData identificationMode={identification.mode} />
      {identification.mode === 'EMAIL' ? <EmailPilotNotes /> : <WhoFlies />}
      {identification.mode === 'EMAIL' && (
        <Section>
          <ConsentFields namespace="consents" />
        </Section>
      )}
      <PeriodAndPayment mode="register-person" showEmailConfirm={identification.mode === 'STRONG'} />
    </Form>
  )
}

const PersonalData: Comp<{ identificationMode: Identification.Mode }> = ({ identificationMode }) => {
  const { t } = useTranslation('personal_data')

  const mode = identificationMode === 'STRONG' ? 'with-ssn' : 'no-ssn'

  return (
    <Section title={t('personal_data')}>
      <p>{t('person_operator_form_description')}</p>
      <PersonFields disabled={identificationMode === 'STRONG'} mode={mode} />
      <AddressGroup kind={identificationMode === 'STRONG' ? 'non-easa' : 'domestic-or-non-easa'} />
      <ContactFields disableEmail={identificationMode === 'EMAIL'} />
      {identificationMode === 'EMAIL' ? <LanguageField /> : <p>{t('business_language_note')}</p>}
      {identificationMode === 'STRONG' && <InsuranceField />}
    </Section>
  )
}

const WhoFlies: Comp = () => {
  const { t } = useTranslation('personal_data')
  const { values } = useFormikContext<FormValues<{ registerAsPilot: boolean }>>()

  const noteKey =
    values.registerAsPilot === ''
      ? undefined
      : values.registerAsPilot
      ? 'fly_yourself_selected_note'
      : 'fly_yourself_unselected_note'

  return (
    <Section title={t('who_flies')}>
      <p>{t('who_flies_note')}</p>
      <RadioGroupBooleanField
        name={'registerAsPilot'}
        options={[
          { value: 'true', label: t('register_as_pilot') },
          { value: 'false', label: t('supervise_other_pilots') },
        ]}
      />
      {noteKey && <p>{t(noteKey, { pilotFormName: t('registration:register_other_pilots') })}</p>}
    </Section>
  )
}

const EmailPilotNotes: Comp = () => {
  const { t } = useTranslation('personal_data')

  return (
    <Section title={t('who_flies')}>
      <p>{t('fly_yourself_selected_note')}</p>
      <p>{t('registration:email_identification_pilot_limitation')}</p>
    </Section>
  )
}
