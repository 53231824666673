import { Form, Formik } from 'formik'
import React, { useContext, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import { Locale, Routing, validators } from 'traficom-registry-shared'
import { api } from '../../services/api'
import { actions, RegistrationContext } from '../../state/registration'
import { CancelButton, FormSubmitStatus, Section, Submit, SubmitButton, TextField } from '../../ui-common/form'
import { H3 } from '../../ui-common/typography/heading'
import PageMeta from '../metadata/page-meta'

type FormValues = { email: string }

export const StartVerification: React.FC = () => {
  const { i18n, t } = useTranslation('registration')
  const { state, dispatch } = useContext(RegistrationContext)
  const [uiState, setUiState] = useState(FormSubmitStatus.NOT_SENT)

  const recaptchaRef = useRef<ReCAPTCHA>(null)

  const submit: Submit<FormValues> = async ({ email }) => {
    const recaptchaValue = await recaptchaRef.current?.executeAsync()

    if (!recaptchaValue) {
      // What should we do in this case?
      setUiState(FormSubmitStatus.ERROR)
      return
    }

    dispatch(actions.identify({ mode: 'EMAIL', identified: false, data: { email } }))

    const userLanguage = i18n.language as Locale.UserLanguage
    const result = await api.startVerification({ email, userLanguage }, { recaptchaValue })

    setUiState(result.success ? FormSubmitStatus.SUCCESS : FormSubmitStatus.ERROR)
  }

  const title = t('email_identification')

  return (
    <Section title={title}>
      <PageMeta subTitle={title} />
      <p>{t('email_identification_instruction')}</p>
      <Formik initialValues={{ email: '' }} onSubmit={submit} validate={validators.startVerification}>
        <Form>
          <TextField label={t('email')} name="email" />
          <SubmitButton disabled={!state.recaptchaKey} labelKey="registration:start_verification" />
          <CancelButton to={Routing.getStepPath('choose-identification')} />
          {state.recaptchaKey && (
            <ReCAPTCHA hl={i18n.language} ref={recaptchaRef} sitekey={state.recaptchaKey} size="invisible" />
          )}
        </Form>
      </Formik>
      {uiState === FormSubmitStatus.SUCCESS && <Redirect push to={Routing.getStepPath('confirm-email')} />}
      {uiState === FormSubmitStatus.ERROR && <H3>{t('common:errors.check_email_address')}</H3>}
    </Section>
  )
}
