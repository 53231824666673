import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Registration, Routing } from 'traficom-registry-shared'
import { AuthService } from '../../services/auth-service'
import { actions, RegistrationContext } from '../../state/registration'
import { Section } from '../../ui-common/form'
import Loader from '../../ui-common/loader/loader'
import PageMeta from '../metadata/page-meta'

export const IdentifyStrong: React.FC<Registration.Context> = props => {
  const { i18n, t } = useTranslation('registration')
  const { dispatch } = useContext(RegistrationContext)

  useEffect(() => {
    const fetchLoginUrl = async () => {
      const result = await AuthService.fetchLoginUrl(i18n.language, Routing.getFullPath({ ...props, step: 'enter-info' }))
      if (result.success) {
        window.location.assign(result.data.url)
      } else {
        dispatch(actions.addSnackbarMessage(t('errorpage:login-failed-title'), 'ERROR'))
      }
    }
    fetchLoginUrl()
  }, [dispatch, i18n.language, props, t])

  const title = t('strong_identification')

  return (
    <Section title={title}>
      <PageMeta subTitle={title} />
      <Loader text={t('strong_redirect_note')} />
    </Section>
  )
}
