import * as Registration from './Registration'

export type FullParams = Registration.Context & { step: Registration.Step }

export const patterns = {
  frontPage: '/',
  droneRoot: '/drone',
  droneInfo: '/drone/info',
  cookieInfo: '/cookies',
  accessibilityStatement: '/accessibility',
  extendRoleRoot: '/extend-:role',
  extendMainRoot: '/extend-:role-:operatorKind',
  registrationRoleRoot: '/register-:role',
  registrationMainRoot: '/register-:role-:operatorKind',
  step: ':step',
  orderStatus: 'order/:orderNumber',
  updateRoleRoot: '/update-:role',
  updateMainRoot: '/update-:role-:operatorKind',
  errorPage: '/error/:errorParam?',
} as const

export const getRolePath = (action: Registration.Action, role: Registration.Role): string => `/${action}-${role}`

export const getMainPath = (
  action: Registration.Action,
  role: Registration.Role,
  operatorKind: Registration.PilotOperatorKind,
): string => `/${action}-${role}-${operatorKind}`

export const getStepPath = (step: Registration.Step): string => step

export const getFullPath = (params: FullParams): string => `${getRootPath(params)}/${getStepPath(params.step)}`

export const getOrderPath = (context: Registration.Context, orderNumber: string): string =>
  `${getRootPath(context)}/order/${orderNumber}`

const getRootPath = ({ action, operatorKind, role }: Registration.Context): string =>
  operatorKind ? getMainPath(action, role, operatorKind) : getRolePath(action, role)

export const ErrorPageParams = ['generic', 'not-found', 'login-failed'] as const
export type ErrorPageError = typeof ErrorPageParams[number]
export const getErrorPagePath = (error?: ErrorPageError): string => (error ? `/error/${error}` : '/error')
