import React from 'react'
import { Comp } from '../../utils/component'

export const Paragraphs: Comp<{ children: string }> = ({ children }) => (
  <>
    {children.split('\n\n').map((text, index) => (
      <p key={index}>{text}</p>
    ))}
  </>
)
