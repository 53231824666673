import React, { useContext } from 'react'
import styled from 'styled-components'
import { Comp } from '../../utils/component'
import LanguageSelector from '../language-selector/language-selector'
import { useTranslation } from 'react-i18next'
import { Identification, Locale, Routing } from 'traficom-registry-shared'
import { RegistrationContext } from '../../state/registration'
import { Link } from 'react-router-dom'
import { HeaderMenu, HeaderMenuItem, HeaderMenuToggle } from '../header-menu/header-menu'
import { ButtonBase } from '../button/button'
import { ReactComponent as Menu } from '../../images/menu.svg'
import { ReactComponent as X } from '../../images/x.svg'
import UserMenu from '../user-menu/user-menu'

const getDataText = (i: Identification.IdentifiedState) =>
  i.mode === 'EMAIL' ? i.data.email : `${i.data.lastName}, ${i.data.firstNames}`

const BarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.theme.spacing(0.5)} 0;
  width: 100%;
`

const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const AppHeading = styled.span`
  color: ${p => p.theme.palette.black};
  font-size: 1.5rem;
  margin: 0;
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const StyledMicroText = styled.span`
  display: block;
  font-size: 11px;
  margin-bottom: ${p => p.theme.spacing(0.5)};
`

const UserMenuItem = styled(ButtonBase)`
  margin: ${p => p.theme.spacing(0.2)} 0;
  color: ${props => props.theme.palette.text.dark};
  font-size: 1rem;
  white-space: nowrap;
  text-align: left;
  padding-left: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`
const StyledHeaderMenuToggle = styled(HeaderMenuToggle)`
  padding: 0;

  & > svg {
    height: 22px;
    width: 22px;
    margin: ${p => p.theme.spacing(0.5)};
  }
`

// For screen readers only (Accessibility)
const StyledSROnlyText = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`

const languageTexts: Record<Locale.UserLanguage, string> = {
  fi: 'Suomeksi',
  sv: 'På Svenska',
  en: 'In English',
}

const MOBILE_MENU_ID = 'mobile_menu'

export const TopBar: Comp = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const { t, i18n } = useTranslation()
  const changeLanguage = (language: Locale.UserLanguage): void => {
    i18n.changeLanguage(language)
  }

  const { state } = useContext(RegistrationContext)
  const { identification } = state
  const isIdentified = identification.identified

  const logout = () => {
    window.location.href = `/api/logout?lang=${i18n.language}`
  }

  const identifiedAs = identification.identified ? getDataText(identification) : ''

  return (
    <>
      <BarContainer>
        <StyledLink to={Routing.patterns.frontPage}>
          <AppHeading>SkyNavX</AppHeading>
        </StyledLink>
        <ActionContainer>
          {isIdentified && <UserMenu loginText={identifiedAs} logout={logout} logoutText={t('common:logout')} />}
          <LanguageSelector />
          <StyledHeaderMenuToggle menuId={MOBILE_MENU_ID} onClick={() => setIsOpen(!isOpen)} isActive={isOpen}>
            <StyledSROnlyText>{isOpen ? t('close_menu') : t('open_menu')}</StyledSROnlyText>
            {isOpen ? <X role="presentation" /> : <Menu role="presentation" />}
          </StyledHeaderMenuToggle>
        </ActionContainer>
      </BarContainer>
      {isOpen && (
        <HeaderMenu id={MOBILE_MENU_ID}>
          {isIdentified && (
            <HeaderMenuItem>
              <UserMenuItem onClick={logout}>
                <StyledMicroText>{identifiedAs}</StyledMicroText>
                {t('common:logout')}
              </UserMenuItem>
            </HeaderMenuItem>
          )}
          <>
            {Locale.languageOptions.map(language => (
              <HeaderMenuItem key={language}>
                <UserMenuItem onClick={() => changeLanguage(language)}>{languageTexts[language]}</UserMenuItem>
              </HeaderMenuItem>
            ))}
          </>
        </HeaderMenu>
      )}
    </>
  )
}
