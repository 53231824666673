import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// import { api } from '../../services/api'
import { /*actions,*/ RegistrationContext } from '../../state/registration'

const AppSettings: React.FunctionComponent = () => {
  const { dispatch, state } = useContext(RegistrationContext)
  const { t } = useTranslation()

  useEffect(() => {
    // let cancelled = false

    const callConfig = async () => {
      // const result = await api.config()
      // if (cancelled) {
      //   return
      // }
      // if (result.success) {
      //   dispatch({ type: 'UPDATE_CONFIG', payload: result.data })
      // } else {
      //   dispatch(actions.addSnackbarMessage(t('errors:configuration_fetch_failed'), 'ERROR'))
      // }
    }
    if (!state.recaptchaKey) {
      callConfig()
    }

    return () => {
      true
    }
  }, [dispatch, t, state.recaptchaKey])
  return <></>
}

export default AppSettings
