import produce from 'immer'
import { Codeset, Feature, Identification, Locale } from 'traficom-registry-shared'
import { Announcements, CodesetStatus, PageInfo, RegistrationState } from '../../state/registration/registration-context'
import { SnackbarMessage } from '../../ui-common/snackbar/snackbar'
import { Choice } from '../types'

type CodeSetPayload = { codeset: Codeset.Name; status: CodesetStatus; values: Choice[]; language: Locale.UserLanguage }
type ConfigPayload = { recaptchaKey?: string; features: Feature.Flags }

export type Action =
  | { type: 'UPDATE_CODESET_VALUES'; payload: CodeSetPayload }
  | { type: 'UPDATE_IDENTIFICATION'; payload: Identification.State }
  | { type: 'UPDATE_CONFIG'; payload: ConfigPayload }
  | { type: 'ADD_SNACKBAR_MESSAGE'; payload: SnackbarMessage }
  | { type: 'REMOVE_SNACKBAR_MESSAGE'; payload: number }
  | { type: 'SET_ANNOUNCEMENTS'; payload: Announcements }
  | { type: 'UPDATE_PAGE_INFO'; payload: PageInfo }

const reducer = (prevState: RegistrationState, action: Action): RegistrationState => {
  switch (action.type) {
    case 'UPDATE_CODESET_VALUES':
      const { codeset, values, status, language } = action.payload

      return produce(prevState, state => {
        state.codesets[language][codeset] = { status, values }
      })
    case 'UPDATE_IDENTIFICATION':
      return {
        ...prevState,
        identification: action.payload,
      }
    case 'UPDATE_CONFIG':
      return { ...prevState, recaptchaKey: action.payload.recaptchaKey ?? '', features: action.payload.features }
    case 'ADD_SNACKBAR_MESSAGE':
      return { ...prevState, snackbarMessage: action.payload }
    case 'REMOVE_SNACKBAR_MESSAGE':
      return { ...prevState, snackbarMessage: null }
    case 'SET_ANNOUNCEMENTS':
      return { ...prevState, announcements: action.payload }
    case 'UPDATE_PAGE_INFO':
      return {
        ...prevState,
        page: {
          title: action.payload.title ?? prevState.page.title,
          // Reset subtitle when main title is set:
          subTitle: action.payload.title ? action.payload.subTitle : action.payload.subTitle ?? prevState.page.subTitle,
        },
      }
  }
}

export { reducer }
