import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Button from './button/button'

export type LinkItem = { labelKey: string; to: string; disabled?: false } | { labelKey: string; disabled: true }

export const ButtonLinkMenu: React.FC<{ items: LinkItem[] }> = ({ items }) => {
  return (
    <div>
      {items.map(item => (
        <MenuItem key={item.labelKey} item={item} />
      ))}
    </div>
  )
}

const MenuItem: React.FC<{ item: LinkItem }> = ({ item }) => {
  const { t } = useTranslation()

  return item.disabled ? (
    <Button disabled $fullWidth>
      {t(item.labelKey)}
    </Button>
  ) : (
    <Button as={Link} to={item.to} $fullWidth>
      {t(item.labelKey)}
    </Button>
  )
}
