export const roles = ['operator', 'pilot'] as const
export type Role = typeof roles[number]
export const isRole = (role: string): role is Role => roles.includes(role as Role)

export const operatorKinds = ['organization', 'person'] as const
export type OperatorKind = typeof operatorKinds[number]
export const isOperatorKind = (kind?: string): kind is OperatorKind => operatorKinds.includes(kind as OperatorKind)

const actions = ['extend', 'register', 'update'] as const
export type Action = typeof actions[number]
const isAction = (action: string): action is Action => actions.includes(action as Action)

const pilotOperatorKinds = ['organization', 'person', 'independent'] as const
export type PilotOperatorKind = typeof pilotOperatorKinds[number]
const isPilotOperatorKind = (kind?: string): kind is PilotOperatorKind =>
  pilotOperatorKinds.includes(kind as PilotOperatorKind)

export type Context =
  | { action: 'register'; role: 'operator'; operatorKind: OperatorKind }
  | { action: 'register'; role: 'pilot'; operatorKind: PilotOperatorKind }
  | { action: 'update'; role: 'operator'; operatorKind: OperatorKind }
  | { action: 'update'; role: 'pilot'; operatorKind?: undefined }
  | { action: 'extend'; role: 'operator'; operatorKind: OperatorKind }
type ContextWithoutKind = Omit<Context, 'operatorKind'> & { operatorKind?: undefined }

export const getContext = (action: string, role: string, operatorKind?: string): Context | undefined => {
  if (role === 'operator') {
    return isAction(action) && isOperatorKind(operatorKind) ? { action, role, operatorKind } : undefined
  }
  if (role === 'pilot') {
    if (action === 'register') {
      if (operatorKind === 'organization') {
        return undefined
      }
      return isPilotOperatorKind(operatorKind) ? { action, role, operatorKind } : undefined
    }
    if (action === 'update') {
      return operatorKind === undefined ? { action, role } : undefined
    }
  }

  return undefined
}

export const getTitlePhrase = (c: Context | ContextWithoutKind): string =>
  c.action === 'extend'
    ? 'registration:period_extension'
    : c.operatorKind === 'independent'
    ? 'ng_frontpage:pilot_test_title'
    : headingKeys[c.action][c.role]

const headingKeys: Record<'register' | 'update', Record<Role, string>> = {
  register: {
    operator: 'registration:register_as_operator',
    pilot: 'registration:register_other_pilots',
  },
  update: {
    operator: 'registration:operator_info_change',
    pilot: 'registration:pilot_info_change',
  },
}

/**
 * Registration period options
 *
 * Same values as the KdDroneRegistrationPeriod Traficom codeset
 */
export const periodOptions = ['1', '3', '5'] as const
export type Period = typeof periodOptions[number]

export const periodCosts: Record<Period, number> = {
  '1': 30, // 1 year
  '3': 75, // 3 years
  '5': 100, // 5 years
}

export const periodYears: Record<Period, number> = {
  '1': 1,
  '3': 3,
  '5': 5,
}

export const pilotTestCost = 30

export const steps = [
  'choose-identification',
  'identify-strong',
  'send-email',
  'confirm-email',
  'enter-info',
  'completed',
] as const

export type Step = typeof steps[number]
