import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Identification, Locale, Registration } from 'traficom-registry-shared'
import useCodeset from '../../../hooks/use-codeset'
import { RadioSelectField, TextField } from '../../../ui-common/form'
import InfoBox from '../../../ui-common/info-box/info-box'
import Loader from '../../../ui-common/loader/loader'
import { Comp } from '../../../utils/component'

export const GenderField: Comp<{ name: string }> = ({ name }) => {
  const { t } = useTranslation('personal_data')
  const codesetState = useCodeset('Sukupuoli')

  if (codesetState.status !== 'LOADED') {
    return <Loader />
  }

  return <RadioSelectField label={t('gender')} name={name} options={codesetState.values} />
}

export const Hello: Comp<{ person: Identification.StrongData }> = ({ person }) => (
  <Trans i18nKey="common:hello">
    Hei <strong>{{ name: `${person.firstNames} ${person.lastName}` }}</strong>!
  </Trans>
)

export const InsuranceField: Comp = () => {
  const { t } = useTranslation('personal_data')

  return (
    <>
      <InfoBox>{t('insurance_info_additional_information')}</InfoBox>
      <TextField label={t('insurance_info')} name="insuranceInfo" optional />
    </>
  )
}

export const LanguageField: Comp = () => {
  const { t } = useTranslation('personal_data')
  const options = Locale.languageOptions.map(value => ({ value, label: t(languageLabelKeys[value]) }))

  return <RadioSelectField label={t('business_language')} name="language" options={options} />
}

const languageLabelKeys: Record<Locale.UserLanguage, string> = { en: 'english', fi: 'finnish', sv: 'swedish' }

export const RegistrationPeriod: Comp = () => {
  const { t } = useTranslation('personal_data')
  const options = Registration.periodOptions.map(value => ({
    value,
    label: t('common:year_count', { count: Registration.periodYears[value] }),
  }))

  return <RadioSelectField label={t('registration_period')} name="registrationPeriod" options={options} />
}
