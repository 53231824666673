import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { keyframes } from 'styled-components'
import { ReactComponent as InfoIcon } from '../../images/info.svg'

const StyledInfoContainer = styled.div`
  position: relative;
  min-height: 62px;
  padding: ${p => p.theme.spacing(2)};
  margin: ${p => p.theme.spacing(1)} 0 ${p => p.theme.spacing(1.5)};
  background-color: ${p => p.theme.palette.white};
  border-bottom: ${p => p.theme.borders['l']} solid ${p => p.theme.palette.secondary};
  overflow: hidden;
`

const StyledSummary = styled.summary`
  padding: ${p => p.theme.spacing(0.2)} ${p => p.theme.spacing(1)};
  cursor: pointer;
  list-style: none;
  color: ${p => p.theme.palette.grey[700]};
  transition: color 0.3s ease-out;
  &::-webkit-details-marker {
    display: none;
  }
  &:hover {
    color: ${p => p.theme.palette.grey[900]};
  }
`

const slide = keyframes`
  0% {
    transform: translateY(-8px);
  }
  100% {
    transform: translateY(0);
  }
`

const StyledDetails = styled.details`
  margin-top: ${props => props.theme.spacing(1)};
  &[open] > ${StyledInfoContainer} {
    animation: 1s ${slide} cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
`

const StyledIcon = styled(InfoIcon)`
  height: 22px;
  margin-top: -${p => p.theme.spacing(0.2)};
  margin-right: ${p => p.theme.spacing(0.4)};
`

const InfoBox: React.FunctionComponent = ({ children }) => {
  const { t } = useTranslation('common')

  return (
    <StyledDetails>
      <StyledSummary>
        <StyledIcon />
        {t('additional_information')}
      </StyledSummary>
      <StyledInfoContainer>{children}</StyledInfoContainer>
    </StyledDetails>
  )
}
export default InfoBox
