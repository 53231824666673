import React, { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, Redirect } from 'react-router-dom'
import { Identification as _Identification, Registration, Routing } from 'traficom-registry-shared'
import { RegistrationContext } from '../../state/registration'
import { Button, Markdown } from '../../ui-common'
import { Section } from '../../ui-common/form'
import { Comp } from '../../utils/component'
import PageMeta from '../metadata/page-meta'

export const Identification: Comp<Registration.Context> = props => {
  const { t } = useTranslation('registration')

  return (
    <Section title={t('identification')}>
      <PageMeta subTitle={t('identification')} />
      {props.action === 'update' ? (
        <UpdateIdentification />
      ) : props.action === 'extend' ? (
        <ExtendIdentification operatorKind={props.operatorKind} />
      ) : props.role === 'operator' || props.operatorKind === 'independent' ? (
        <OperatorIdentification operatorKind={props.operatorKind} />
      ) : props.operatorKind === 'organization' ? (
        <Redirect to={Routing.getStepPath('enter-info')} />
      ) : (
        <PilotPersonIdentification />
      )}
    </Section>
  )
}

const ExtendIdentification: Comp<{ operatorKind: Registration.OperatorKind }> = ({ operatorKind }) =>
  operatorKind === 'organization' ? <Redirect to={Routing.getStepPath('enter-info')} /> : <UpdateIdentification />

const UpdateIdentification: Comp = () => {
  const { t } = useTranslation('registration')
  const { state } = useContext(RegistrationContext)
  const { identification } = state

  const identified = identification.identified && identification.mode === 'STRONG'

  return (
    <>
      {identified && identification.identified && <IdentifiedUser identification={identification} />}
      <p>{t('strong_identification_note')}</p>
      <StepLink step="identify-strong" variant={!identified ? 'primary' : 'secondary'}>
        {t('strong_identification')}
      </StepLink>
      {!identified && (
        <>
          <hr />
          <p>{t('update_non_identification_note')}</p>
          <StepLink step="enter-info" variant="secondary">
            {t('continue_without_identification')}
          </StepLink>
        </>
      )}
    </>
  )
}

const OperatorIdentification: Comp<{ operatorKind: Registration.PilotOperatorKind }> = ({ operatorKind }) => {
  const { t } = useTranslation('registration')
  const { state } = useContext(RegistrationContext)
  const { identification } = state

  return (
    <>
      {identification.identified && <IdentifiedUser identification={identification} />}
      {operatorKind === 'independent' && <p>{t('pilot_independent_note')}</p>}
      <p>{t('strong_identification_note')}</p>
      <StepLink step="identify-strong" variant={!identification.identified ? 'primary' : 'secondary'}>
        {t('strong_identification')}
      </StepLink>
      <hr />
      <Markdown>{t('email_identification_disabled')}</Markdown>
      {/* Email identification is disabled for now. */}
    </>
  )
}

const getDataText = (i: _Identification.IdentifiedState) =>
  i.mode === 'EMAIL' ? i.data.email : `${i.data.lastName}, ${i.data.firstNames}`

const IdentifiedUser: Comp<{ identification: _Identification.IdentifiedState }> = ({ identification }) => {
  const { t } = useTranslation('registration')
  const name = getDataText(identification)
  return (
    <>
      <p>
        <Trans i18nKey="registration:identified_as">
          Olet tunnistautunut käyttäjänä <strong>{{ name }}</strong>. Haluatko jatkaa?
        </Trans>
      </p>
      <StepLink step="enter-info" variant="primary">
        {t('registration:continue_as_identified')}
      </StepLink>
      <hr />
    </>
  )
}

const PilotPersonIdentification: Comp = () => {
  const { t } = useTranslation('registration')
  const { state } = useContext(RegistrationContext)
  const { identification } = state

  if (!identification.identified || identification.mode !== 'STRONG') {
    return <Redirect to={Routing.getStepPath('identify-strong')} />
  }

  return (
    <>
      <IdentifiedUser identification={identification} />
      <p>{t('strong_identification_note')}</p>
      <StepLink step="identify-strong" variant="secondary">
        {t('strong_identification')}
      </StepLink>
    </>
  )
}

const StepLink: React.FC<{ step: Registration.Step; variant?: 'primary' | 'secondary' }> = ({ children, step, variant }) => (
  <Button as={Link} to={Routing.getStepPath(step)} variant={variant || 'secondary'} $fullWidth>
    {children}
  </Button>
)
