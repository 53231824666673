import React, { SyntheticEvent, useContext } from 'react'
import styled from 'styled-components'
import { Container } from '../layout/layout'
import FintrafficHeader, { Links } from './fintraffic-header'
import { TopBar } from './top-bar'
import { Locale } from 'traficom-registry-shared'
import { isDuring, useAnnouncements } from '../../services/announcement-service'
import { RegistrationContext } from '../../state/registration'
import { mixins } from '../../styling/mixins'
import { Comp } from '../../utils/component'
import { AlertBanner } from '../alert/alert'
import { useTranslation } from 'react-i18next'

const AppHeader = styled.header`
  background-color: ${props => props.theme.palette.black};
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);

  /* Hidden from print, for example to allow printing a receipt without extra banners. */
  @media print {
    visibility: hidden;
  }
`

const ColorContainer = styled.div<{ light?: boolean }>`
  background-color: ${props => (props.light ? props.theme.palette.white : props.theme.palette.black)};
  color: ${props => props.theme.palette.text.dark};
`
const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
`

const StyledSkipLink = styled.a`
  margin-right: 1rem;
  position: absolute;
  transform: translateX(-150%);

  &:focus {
    position: static;
    transform: translateX(0);
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: white;
    height: 32px;
    align-items: center;
  }
`

const Bar: React.FC<{ light?: boolean }> = ({ light, children }) => (
  <ColorContainer light={light}>
    <Container>
      <Wrapper>{children}</Wrapper>
    </Container>
  </ColorContainer>
)

const fintrafficLinks: Links = [
  {
    fi: { title: 'Liikennetilanne', href: 'https://liikennetilanne.fintraffic.fi/' },
    sv: { title: 'Trafikläget', href: 'https://liikennetilanne.fintraffic.fi/?lang=swe' },
    en: { title: 'Traffic Situation', href: 'https://liikennetilanne.fintraffic.fi/?lang=eng' },
  },
  {
    fi: { title: 'Palauteväylä', href: 'https://palautevayla.fi/aspa?lang=fi' },
    sv: { title: 'Responskanalen', href: 'https://palautevayla.fi/aspa?lang=sv' },
    en: { title: 'Feedback Channel', href: 'https://palautevayla.fi/aspa?lang=en' },
  },
  {
    fi: { title: 'Junalähdöt', href: 'https://junalahdot.fi/junalahdot/main?lang=1' },
    sv: { title: 'Tågavgångar', href: 'https://junalahdot.fi/junalahdot/main?lang=2' },
    en: { title: 'Train Departures', href: 'https://junalahdot.fi/junalahdot/main?lang=3' },
  },
  {
    fi: { title: 'Drone-palvelut', href: '/drone' },
    sv: { title: 'Drone-tjänster', href: '/drone' },
    en: { title: 'Drone services', href: '/drone' },
  },
  {
    fi: { title: 'Digitraffic', href: 'https://www.digitraffic.fi/' },
    sv: { title: 'Digitraffic', href: 'https://www.digitraffic.fi/' },
    en: { title: 'Digitraffic', href: 'https://www.digitraffic.fi/en/' },
  },
  {
    fi: { title: 'Digitransit', href: 'https://digitransit.fi/' },
    sv: { title: 'Digitransit', href: 'https://digitransit.fi/' },
    en: { title: 'Digitransit', href: 'https://digitransit.fi/en/' },
  },
  {
    fi: { title: 'NAP', href: 'https://finap.fi/#/' },
    sv: { title: 'NAP', href: 'https://finap.fi/#/' },
    en: { title: 'NAP', href: 'https://finap.fi/#/' },
  },
]

const Header: Comp = () => {
  const announcements = useAnnouncements().filter(a => isDuring(new Date(), a.active))
  const { t, i18n } = useTranslation()
  const currentLanguage = i18n.language as Locale.UserLanguage

  const skipToMainContent = (event: SyntheticEvent) => {
    event.preventDefault()

    const container: HTMLElement | null = document.querySelector('main:first-of-type')

    if (container) {
      container.tabIndex = -1
      container.focus()
      setTimeout(() => container.removeAttribute('tabindex'))
    }
  }

  return (
    <AppHeader data-cy="header">
      <StyledSkipLink href="#main-id" onClick={skipToMainContent}>
        {t('skip_content')}
      </StyledSkipLink>
      <Bar>
        <FintrafficHeader links={fintrafficLinks} />
      </Bar>
      <Bar light>
        <TopBar />
      </Bar>
      <LiveAnnouncer />
      {announcements.map(({ content }) => {
        const { title, text } = content[currentLanguage]
        return <AlertBanner key={title} title={title} text={text} />
      })}
    </AppHeader>
  )
}

const LiveAnnouncer: Comp = () => {
  const { state } = useContext(RegistrationContext)
  const message = [state.page.title ?? [], state.page.subTitle ?? []].flat().join(': ')

  return <LiveWrap aria-live="polite">{message}</LiveWrap>
}

const LiveWrap = styled.div`
  ${mixins.layout.screenReaderOnly}
`

export default Header
