import { Codeset, Locale, Registrant, Registration } from '../model'
import {
  allowOnlyNumbers,
  exact,
  isChecked,
  isFinnishPersonId,
  isIsoDate,
  isIsoDateTime,
  isOperatorIndentifier,
  isPilotIdentifier,
  isUUID,
  isYtunnus,
  noExtraWhitespace,
  notEmpty,
  oneOf,
  phoneNumber,
  typeOf,
  validateEmail,
} from './conditions'
import { Builder, chain, Create, optional, optionalObject } from './util'

const boolean = () => typeOf('boolean')('common:field_validation_errors.not_empty')

export const nonEmpty: Create<string> = () =>
  chain(
    notEmpty('common:field_validation_errors.not_empty'),
    noExtraWhitespace('common:field_validation_errors.no_extra_whitespace'),
  )
export const checked: Create<boolean> = () => isChecked('common:field_validation_errors.mandatory_selection')
export const email: Create<string> = () => chain(nonEmpty(), validateEmail('common:field_validation_errors.email_invalid'))
export const onlyNumbers: Create<string> = () =>
  chain(nonEmpty(), allowOnlyNumbers('common:field_validation_errors.only_numbers'))
export const phone: Create<string> = () => chain(nonEmpty(), phoneNumber('common:field_validation_errors.phone_invalid'))
export const date: Create<string> = () => chain(nonEmpty(), isIsoDate())
export const datetime: Create<string> = () => chain(nonEmpty(), isIsoDateTime())
export const ssn: Create<string> = () =>
  chain(nonEmpty(), isFinnishPersonId('common:field_validation_errors.finnish_pin_invalid'))
export const finnishOrganizationId: Create<string> = () =>
  chain(nonEmpty(), isYtunnus('common:field_validation_errors.finnish_oid_invalid'))
export const operatorIdentifier: Create<string> = () =>
  chain(nonEmpty(), isOperatorIndentifier('common:field_validation_errors.operator_identifier_invalid'))
export const pilotIdentifier: Create<string> = () =>
  chain(nonEmpty(), isPilotIdentifier('common:field_validation_errors.pilot_identifier_invalid'))
export const changeIdentifier: Create<string> = () =>
  chain(nonEmpty(), isUUID('common:field_validation_errors.change_identifier_invalid'))

export const language: Create<string> = () =>
  oneOf<string>(Locale.languageOptions)('common:field_validation_errors.mandatory_selection')

export const codesetName: Create<string> = () => oneOf<string>(Codeset.names)()

const registrationPeriod = () => oneOf(Registration.periodOptions)('common:field_validation_errors.mandatory_selection')

const authType = oneOf(['EMAIL', 'STRONG'])
const operatorKind = oneOf(['organization', 'person'])

export const startVerification: Builder<{ email: string }> = () => ({ email })
export const verifyEmail: Builder<{ email: string; code: string }> = () => ({ email, code: onlyNumbers })

const address: Builder<Registrant.Address> = () => ({
  street: nonEmpty,
  municipality: nonEmpty,
  postalCode: nonEmpty,
  country: nonEmpty,
})

const consents: Builder<Registrant.Consents> = () => ({
  developmentAndInnovationActivities: boolean,
  directMarketing: boolean,
  individualRelease: boolean,
  openInterfaces: boolean,
  transportRelatedPurposes: boolean,
})

const someConsents: Builder<Partial<Registrant.Consents>> = () => ({
  developmentAndInnovationActivities: optional(boolean),
  directMarketing: optional(boolean),
  individualRelease: optional(boolean),
  openInterfaces: optional(boolean),
  transportRelatedPurposes: optional(boolean),
})

const contactInfo: Builder<Registrant.ContactInfo> = () => ({ email, phone })

const personCommon: Builder<Registrant.PersonBase & Registrant.ContactInfo> = values => ({
  ...contactInfo(values),
  firstNames: nonEmpty,
  lastName: nonEmpty,
})

const personWithConsents: Builder<Registrant.PersonWithConsents> = values => ({
  ...personCommon(values),
  birthDate: date,
  birthCountry: nonEmpty,
  nationality: nonEmpty,
  gender: nonEmpty,
  consents,
})

const contactPerson: Builder<Registrant.ContactPerson> = values =>
  values?.authType === 'STRONG'
    ? personCommon(values)
    : values?.authType === 'EMAIL'
    ? personWithConsents(values)
    : { authType }

export const operatorPerson: Builder<Registrant.OperatorPerson> = values =>
  values?.authType === 'STRONG'
    ? operatorPersonStrong(values)
    : values?.authType === 'EMAIL'
    ? operatorPersonEmail(values)
    : { authType }

export const operatorOrganization: Builder<Registrant.OperatorOrganization> = values => ({
  contact: contactPerson,
  name: nonEmpty,
  finnishOrganizationId: values?.hasFinnishId ? finnishOrganizationId : undefined,
  email,
  phone,
  address: values?.hasFinnishId ? undefined : address,
  language: values?.hasFinnishId ? undefined : language,
  signingAuthority: checked,
  confirmationOfCompetence: checked,
  developmentAndInnovationActivities: values?.hasFinnishId ? undefined : boolean,
  hasFinnishId: boolean,
  registrationPeriod,
  insuranceInfo: optional(nonEmpty),
  foreignOrganizationId: optional(nonEmpty),
})

const isForeignAddress = (address?: Registrant.Address): boolean => address !== undefined && address.country !== 'FI'

const operatorPersonStrong: Builder<Registrant.OperatorPersonStrongAuth> = values => ({
  ...personCommon(values),
  address: isForeignAddress(values?.address) ? address : undefined,
  confirmationOfCompetence: checked,
  registerAsPilot: boolean,
  registrationPeriod,
  insuranceInfo: optional(nonEmpty),
})

const operatorPersonEmail: Builder<Registrant.OperatorPersonEmailAuth> = values => ({
  ...personWithConsents(values),
  address,
  language,
  confirmationOfCompetence: checked,
  registrationPeriod,
  insuranceInfo: optional(nonEmpty),
})

export const operator: Builder<Registrant.Operator> = values =>
  values?.operatorKind === 'organization'
    ? operatorOrganization(values)
    : values?.operatorKind === 'person'
    ? operatorPerson(values)
    : { operatorKind }

const pilot: Builder<Registrant.PilotDetails> = values => ({
  ...personCommon(values),
  ssn,
  address: isForeignAddress(values?.address) ? address : undefined,
})

const operatorPilotBase: Builder<Registrant.OperatorPilotBase> = () => ({
  operatorIdentifier,
  pilot,
})

export const operatorPilot: Builder<Registrant.OperatorPilot> = values =>
  values?.operatorKind === 'organization'
    ? operatorPilotOrganization(values)
    : values?.operatorKind === 'person'
    ? operatorPilotPerson(values)
    : { operatorKind }

export const operatorPilotOrganization: Builder<Registrant.OperatorPilotOrganization> = values => ({
  ...operatorPilotBase(values),
  changeIdentifier,
  operatorKind: exact('organization'),
})

export const operatorPilotPerson: Builder<Registrant.OperatorPilotPerson> = values => ({
  ...operatorPilotBase(values),
  operatorKind: exact('person'),
})

export const independentPilot: Builder<Registrant.IndependentPilot> = values => ({
  ...(values?.authType === 'EMAIL' ? personWithConsents(values) : contactInfo(values)),
  language: values?.authType === 'EMAIL' ? language : undefined,
  address: values?.authType === 'EMAIL' ? address : optionalObject(address),
})

// Type inference fails here without the casts. Too many discriminators?
export const updateOperator: Builder<Registrant.UpdateOperator> = values => {
  if (values?.operatorKind === 'organization') {
    return updateOperatorOrganization(values) as ReturnType<Builder<Registrant.UpdateOperator>>
  }
  if (values?.operatorKind === 'person') {
    return updateOperatorPerson(values) as ReturnType<Builder<Registrant.UpdateOperator>>
  }

  return { operatorKind }
}

export const updateOperatorOrganization: Builder<Registrant.UpdateOperatorOrganization> = values => ({
  ...updateOperatorBase(values),
  changeIdentifier: values?.authType === 'STRONG' ? undefined : changeIdentifier,
  contact:
    values?.contactAction === 'replace'
      ? values?.authType === 'STRONG'
        ? contactInfo
        : personWithConsents
      : partialContactInfo,
})

export const updateOperatorPerson: Builder<Registrant.UpdateOperatorPerson> = values => ({
  ...updateOperatorBase(values),
  changeIdentifier: values?.authType === 'STRONG' ? undefined : changeIdentifier,
  consents: someConsents,
})

const updateOperatorBase: Builder<Registrant.UpdateOperatorBase> = () => ({
  operatorIdentifier,
  email: optional(email),
  phone: optional(phone),
  address: optionalObject(address),
  language: optional(language),
  insuranceInfo: optional(nonEmpty),
})

const partialContactInfo: Builder<Registrant.ContactInfo> = () => ({
  email: optional(email),
  phone: optional(phone),
})

export const updatePilot: Builder<Registrant.UpdatePilot> = values => ({
  pilotIdentifier,
  changeIdentifier: values?.authType === 'STRONG' ? undefined : changeIdentifier,
  email: optional(email),
  phone: optional(phone),
  address: optionalObject(address),
  language: values?.authType === 'STRONG' ? undefined : optional(language),
  consents: someConsents,
})

export const extendOperator: Builder<Registrant.ExtendOperator> = values => ({
  operatorIdentifier,
  changeIdentifier: values?.authType === 'STRONG' ? undefined : changeIdentifier,
  email,
  registrationPeriod,
})
