import axios from 'axios'
import { api } from './api'
import { Result } from './apiTypes'

const fetchLoginUrl = async (lang: string, target: string): Promise<Result<{ url: string }, undefined>> => {
  try {
    const { data } = await axios.get<{ url: string }>(`/api/login?lang=${lang}&target=${target}`)

    return { success: true, data }
  } catch (e) {
    return { success: false, data: undefined }
  }
}

const AuthService = {
  fetchLoginUrl,
  fetchUserInfo: api.sessionInfo,
}

export { AuthService }
