import { useFormikContext } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Identification, Locale, validators } from 'traficom-registry-shared'
import { api } from '../../services/api'
import { Form, RadioGroupBooleanField, Section, Submit, TextField, useFormErrorHandler } from '../../ui-common/form'
import { Comp } from '../../utils/component'
import { FormValues } from '../../utils/types'
import { AddressGroup, ConsentFields, ContactFields, InsuranceField, LanguageField, PersonFields } from './fields'
import { organizationDefaults, RegisterOrganization } from './operator-data'
import { PeriodAndPayment } from './period-payment-fields'
import InfoBox from '../../ui-common/info-box/info-box'

type Props = { identification: Identification.IdentifiedState }

export const OperatorOrganizationInfo: React.FC<Props> = ({ identification }) => {
  const { i18n } = useTranslation()

  const handleError = useFormErrorHandler<RegisterOrganization>()

  const submit: Submit<RegisterOrganization> = async (values, helpers) => {
    const userLanguage = i18n.language as Locale.UserLanguage
    const result = await api.registerOperator({ operatorKind: 'organization', userLanguage, ...values })
    if (result.success) {
      window.location.href = result.data.url
    } else {
      handleError(result.data, helpers)
    }
  }

  return (
    <Form<RegisterOrganization>
      key={identification.mode} // Needed to reset form values properly if the user changes identification method
      initialValues={organizationDefaults(identification)}
      onSubmit={submit}
      validate={validators.operatorOrganization}
    >
      <ContactPerson identificationMode={identification.mode} />
      {identification.mode === 'EMAIL' && (
        <Section>
          <ConsentFields namespace="contact.consents" />
        </Section>
      )}
      <OrganizationInfo />
      <PeriodAndPayment mode="register-organization" showEmailConfirm={identification.mode === 'STRONG'} />
    </Form>
  )
}

const ContactPerson: Comp<{ identificationMode: Identification.Mode }> = ({ identificationMode }) => {
  const { t } = useTranslation('personal_data')

  const mode = identificationMode === 'STRONG' ? 'with-ssn' : 'no-ssn'

  return (
    <Section title={t('responsible_person_info')}>
      <p>{t('organization_operator_form_description', { pilotFormName: t('registration:register_other_pilots') })}</p>
      <PersonFields disabled={identificationMode === 'STRONG'} mode={mode} namespace="contact" />
      <ContactFields disableEmail={identificationMode === 'EMAIL'} namespace="contact" />
    </Section>
  )
}

const OrganizationInfo: Comp = () => {
  const { t } = useTranslation('personal_data')

  const { values } = useFormikContext<FormValues<RegisterOrganization>>()

  return (
    <Section title={t('organization_info')}>
      <RadioGroupBooleanField
        name={'hasFinnishId'}
        options={[
          {
            label: t('has_finnish_company_id'),
            value: 'true',
          },
          { label: t('has_not_finnish_company_id'), value: 'false' },
        ]}
      />
      {values.hasFinnishId === true && <TextField label={t('company_id')} name="finnishOrganizationId" />}
      {values.hasFinnishId === false && <TextField label={t('foreign_company_id')} name="foreignOrganizationId" optional />}
      {values.hasFinnishId && <InfoBox>{t('organisation_name_info_text')}</InfoBox>}
      <TextField label={t('organisation_name')} name="name" />
      <ContactFields />
      {values.hasFinnishId === false && (
        <>
          <AddressGroup kind="domestic-or-non-easa" />
          <LanguageField />
          <ConsentFields namespace={undefined} forOrganization />
        </>
      )}
      <InsuranceField />
    </Section>
  )
}
