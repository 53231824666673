import React from 'react'
import { useTranslation } from 'react-i18next'
import { Registrant } from 'traficom-registry-shared'
import { FieldGroup, TextField } from '../../../ui-common/form'
import { Comp } from '../../../utils/component'

type Props = { disableEmail?: boolean; namespace?: string; optional?: boolean }

export const ContactFields: Comp<Props> = ({ disableEmail, namespace, optional }) => {
  const { t } = useTranslation('personal_data')

  const examples = ['040 1234567', '+358 40 1234567'].join(` ${t('common:or')} `)
  const placeholder = t('common:for_example', { value: examples })

  return (
    <FieldGroup title={t('contact_information')}>
      <TextField label={t('email')} name={getName(namespace, 'email')} disabled={disableEmail} optional={optional} />
      <TextField label={t('telephone')} name={getName(namespace, 'phone')} optional={optional} placeholder={placeholder} />
    </FieldGroup>
  )
}

const getName = (namespace: string | undefined, name: keyof Registrant.ContactInfo): string =>
  namespace ? `${namespace}.${name}` : name
