import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { Registration, Routing } from 'traficom-registry-shared'
import ErrorPage from '../components/error/error-page'
import Frontpage from '../components/frontpage/frontpage'
import { AccessibilityStatementPage, CookiePolicyPage, InfoPage } from '../components/info-page'
import NGFrontpage from '../components/ng-frontpage/ng-frontpage'
import { RegisterMain, RoleRootSelect } from '../components/register'
import { Comp } from '../utils/component'
import OutOfService from '../components/out-of-service/OutOfService'

export const Routes: Comp = () => (
  <Switch>
    <Route component={OutOfService} path="*" />
    <Route component={Frontpage} path={Routing.patterns.frontPage} exact />
    <Route component={NGFrontpage} path={Routing.patterns.droneRoot} exact />
    <Route component={InfoPage} path={Routing.patterns.droneInfo} exact />
    <Route component={CookiePolicyPage} path={Routing.patterns.cookieInfo} exact />
    <Route component={AccessibilityStatementPage} path={Routing.patterns.accessibilityStatement} exact />
    <Route render={() => <RegistrationRoot action="extend" />} path={Routing.patterns.extendMainRoot} />
    <Route render={() => <RoleRoot action="extend" />} path={Routing.patterns.extendRoleRoot} />
    <Route render={() => <RegistrationRoot action="register" />} path={Routing.patterns.registrationMainRoot} />
    <Route render={() => <RoleRoot action="register" />} path={Routing.patterns.registrationRoleRoot} />
    <Route render={() => <RegistrationRoot action="update" />} path={Routing.patterns.updateMainRoot} />
    <Route render={() => <RoleRoot action="update" />} path={Routing.patterns.updateRoleRoot} />
    <Route component={ErrorPage} path={Routing.patterns.errorPage} />
    <Route render={() => <ErrorPage defaultError="not-found" />} />
  </Switch>
)

const RoleRoot: Comp<{ action: Registration.Action }> = ({ action }) => {
  const { role } = useParams<{ role: string }>()
  if (!Registration.isRole(role) || (role === 'pilot' && action === 'register')) {
    return <ErrorPage defaultError="not-found" />
  }

  return <RoleRootSelect action={action} role={role} />
}

const RegistrationRoot: Comp<{ action: Registration.Action }> = ({ action }) => {
  const { operatorKind, role } = useParams<{ operatorKind: string; role: string }>()
  const context = Registration.getContext(action, role, operatorKind)
  if (context === undefined) {
    return <ErrorPage defaultError="not-found" />
  }

  return <RegisterMain {...context} />
}
