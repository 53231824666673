import { useFormikContext } from 'formik'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import { Routing, validators } from 'traficom-registry-shared'
import { api } from '../../services/api'
import { Paragraphs } from '../../ui-common'
import {
  CancelButton,
  Form,
  RadioSelectField,
  Section,
  Submit,
  SubmitButton,
  useFormErrorHandler,
} from '../../ui-common/form'
import { Comp } from '../../utils/component'
import {
  AddressGroup,
  ConsentFields,
  ContactFields,
  Hello,
  IdentificationFields,
  InsuranceField,
  LanguageField,
  PersonFields,
} from './fields'
import { organizationUpdateDefaults, UpdateAuthMode, UpdateOrganization, UpdateProps } from './operator-data'

export const OperatorOrganizationUpdate: React.FC<UpdateProps> = props => {
  const { mode } = props

  const [succeeded, setSucceeded] = useState(false)
  const handleError = useFormErrorHandler<UpdateOrganization>()

  const submit: Submit<UpdateOrganization> = async (values, helpers) => {
    const result = await api.updateOperator(values)

    if (result.success) {
      setSucceeded(true)
    } else {
      handleError(result.data, helpers)
    }
  }

  if (succeeded) {
    return <Redirect push to={Routing.getStepPath('completed')} />
  }

  return (
    <Form<UpdateOrganization>
      key={mode} // Needed to reset form values properly if the user changes identification method
      initialValues={organizationUpdateDefaults(mode)}
      onSubmit={submit}
      validate={validators.updateOperatorOrganization}
    >
      <CredentialSection {...props} />
      <ContactPersonSection mode={mode} />
      <OperatorSection />
    </Form>
  )
}

const CredentialSection: Comp<UpdateProps> = props => {
  const { t } = useTranslation()

  return (
    <Section>
      {props.mode === 'STRONG' && <Hello person={props.identification} />}
      <p>{t('personal_data:organization_operator_update_description')}</p>
      <IdentificationFields enableChangeIdentifier role="operator" />
    </Section>
  )
}

const ContactPersonSection: Comp<{ mode: UpdateAuthMode }> = ({ mode }) => {
  const { t } = useTranslation()
  const { values } = useFormikContext<UpdateOrganization>()

  const replacing = values.contactAction === 'replace'

  return (
    <>
      <Section title={t('personal_data:responsible_person_info')}>
        <RadioSelectField<UpdateOrganization['contactAction']>
          name="contactAction"
          options={[
            { value: 'update', label: t('registration:responsible_person_update') },
            { value: 'replace', label: t('registration:responsible_person_replace') },
          ]}
        />
        {replacing &&
          (mode === 'NONE' ? (
            <PersonFields mode="no-ssn" namespace="contact" />
          ) : (
            <p>{t('registration:responsible_person_identified_note')}</p>
          ))}
        <ContactFields namespace="contact" optional={!replacing} />
        {mode === 'NONE' && <ConsentFields namespace="contact.consents" />}
      </Section>
    </>
  )
}

const OperatorSection: Comp = () => {
  const { t } = useTranslation()
  const { values } = useFormikContext<UpdateOrganization>()

  return (
    <Section title={t('personal_data:organization_info')}>
      <ContactFields optional />
      <Paragraphs>{t('registration:address_update_business_id_note')}</Paragraphs>
      <AddressGroup kind="any" optional />
      <LanguageField />
      <InsuranceField />
      {values.contact.email ? (
        <p>
          <Trans i18nKey="personal_data:update_data_email_confirm_info">
            Vahvistus tietojen muutoksesta toimitetaan sähköpostiosoitteeseen
            <strong>{{ email: values.contact.email }}</strong>. Tarkista sähköpostin oikeellisuus.
          </Trans>
        </p>
      ) : (
        <p>{t('personal_data:update_operator_same_email')}</p>
      )}
      <SubmitButton labelKey="registration:send_updated_info" />
      <CancelButton to={Routing.patterns.droneRoot} />
    </Section>
  )
}
